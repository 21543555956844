import { useCommonTx } from '~/hooks/useTranslationForNamespace'

export const LoadingOrb = ({ showLoadingText }: { showLoadingText?: boolean } = {}) => {
  const showText = showLoadingText ?? true
  const t = useCommonTx('common')

  return (
    <div className='flex flex-col items-center'>
      <div className='flex gap-1'>
        <AnimatedBall animationDelay='0s' />
        <AnimatedBall animationDelay='0.2s' />
        <AnimatedBall animationDelay='0.3s' />
      </div>
      <div className='text-body-text-2 font-bold mt-6 text-center text-text-default'>
        {showText ? t('loadingWithEllipses') : null}
      </div>
    </div>
  )
}

const PageLoader = () => {
  return (
    <div className='bg-white fixed inset-0 flex justify-center items-center z-[1000]'>
      <LoadingOrb />
    </div>
  )
}

export default PageLoader

const AnimatedBall = ({ animationDelay }: { animationDelay?: string }) => {
  return <div className='rounded-full w-6 h-6 animate-loadingBounce bg-accent-primary' style={{ animationDelay }}></div>
}
