import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: number;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: any;
  JSONObject: any;
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: string;
  /** A field whose value is a Semantic Version: https://semver.org */
  SemVer: any;
};

export type AbridgedAppleNotification = {
  __typename?: 'AbridgedAppleNotification';
  expiresDate?: Maybe<Scalars['Float']>;
  notificationType: Scalars['String'];
  originalTransactionId?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['Float']>;
  signedDate: Scalars['Float'];
  storefront?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type AddDependentUserToSubscriptionInput = {
  dependentUserId: Scalars['Int'];
  subscriptionNumericId: Scalars['Int'];
};

export type AgeCohortComparison = {
  __typename?: 'AgeCohortComparison';
  ageCohortSlug: Scalars['String'];
  bestOverallPercentile?: Maybe<Scalars['Float']>;
  overallPercentile?: Maybe<Scalars['Float']>;
  percentileByArea: Array<AreaPercentile>;
};

export enum AppPlatform {
  Android = 'Android',
  IOs = 'iOS'
}

export type AppleNotificationHistoryInput = {
  /** The end date of the timespan for the requested App Store Server Notification history records. Choose an endDate that’s later than the startDate. If you choose an endDate in the future, the endpoint automatically uses the current date as the endDate. */
  endDate: Scalars['Float'];
  paginationToken?: InputMaybe<Scalars['String']>;
  /** The start date of the timespan for the requested App Store Server Notification history records. The startDate needs to precede the endDate. Choose a startDate that’s within the past 180 days from the current date. */
  startDate: Scalars['Float'];
};

export type AppleNotificationHistoryResponse = {
  __typename?: 'AppleNotificationHistoryResponse';
  abridgedNotifications: Array<AbridgedAppleNotification>;
  hasMore: Scalars['Boolean'];
  paginationToken?: Maybe<Scalars['String']>;
};

export type AppleSubscription = {
  __typename?: 'AppleSubscription';
  originalTransactionId: Scalars['String'];
};

export type AppleSubscriptionInput = {
  bundleId: Scalars['String'];
  currency: Scalars['String'];
  introPrice: Scalars['Float'];
  planPrice: Scalars['Float'];
  transactionId: Scalars['String'];
};

export type AppleTransactionInput = {
  currency: Scalars['String'];
  introPrice: Scalars['Float'];
  planPrice: Scalars['Float'];
  purchasedAt: Scalars['Float'];
  sku: Sku;
  storefront: Scalars['String'];
  subscriptionId: Scalars['String'];
  transactionId: Scalars['String'];
  userId: Scalars['Int'];
};

export type AreaLpi = {
  __typename?: 'AreaLpi';
  areaSlug: Scalars['String'];
  lpi?: Maybe<Scalars['Int']>;
};

export type AreaPercentile = {
  __typename?: 'AreaPercentile';
  areaSlug: Scalars['String'];
  percentile: Scalars['Float'];
};

export enum BillingFrequency {
  Biennially = 'biennially',
  Monthly = 'monthly',
  Once = 'once',
  Yearly = 'yearly'
}

export type BillingLocationInput = {
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type BraintreeClientTokenResponse = {
  __typename?: 'BraintreeClientTokenResponse';
  clientToken: Scalars['String'];
};

export type BraintreeSubscriptionInput = {
  billingLocation: BillingLocationInput;
  currency: Scalars['String'];
  nonce: Scalars['String'];
  promotionCode?: InputMaybe<Scalars['String']>;
  sku: Sku;
};

export type CancelSubscriptionInput = {
  provider: PlanProvider;
  subscriptionId: Scalars['String'];
};

export type CheckDeferredSubscriptionInput = {
  subscriptionId: Scalars['String'];
};

/** Represents the LPI for a user, used for rendering the LPI chart */
export type ConsolidatedGameDatedLpi = {
  __typename?: 'ConsolidatedGameDatedLpi';
  /** Game for which the chart is being rendered */
  game: Game;
  /** List of LPI values for the game */
  lpis: Array<DatedLpi>;
  /** Total number of Game plays for the user */
  totalPlays: Scalars['Int'];
};

export type CreatePromotionInput = {
  isActive: Scalars['Boolean'];
  percentageOff: Scalars['Float'];
  promotionCode: Scalars['String'];
};

export type CreateUserInput = {
  birthdate?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  migratedToKratos: Scalars['Boolean'];
  /** If given, the user will be created with this userId */
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents accumulated results of all plays of an individual game */
export type CumulativeGameResult = {
  __typename?: 'CumulativeGameResult';
  game: Game;
  /** Id generated from game slug */
  id: Scalars['ID'];
  /** When the most recent game play was finished, per client's clock, in epoch milliseconds. */
  lastPlayedAt?: Maybe<Scalars['Float']>;
  /**
   * Number of plays by a `User` for this [game](#game) to reach the next rank.
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  nextRankAt: Scalars['Int'];
  /**
   * The number of game plays a user has completed after achieving their current rank.
   * For example the rank newcomer is achieved after completing 3 gameplays and the next rank is achieved after 8 game plays. So if a user has 5 gameplays, plays at rank is calculated as `5 - 3`.
   */
  playsAtRank: Scalars['Int'];
  /**
   * String representation of a `User`'s current rank for this [game](#game).
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  rank: Scalars['String'];
  /** Total number of times the user played this game */
  totalPlays: Scalars['Int'];
  /** Id of user completing the game */
  userId: Scalars['Int'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  id?: Maybe<Scalars['Int']>;
  settings?: Maybe<UiSettings>;
};

export type DatedLpi = {
  __typename?: 'DatedLpi';
  /** Date of the gameplay */
  finishedAt: Scalars['Float'];
  /** LPI's value for the gameplay */
  value: Scalars['Int'];
};

export enum DayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type DeleteUnderageUsersInput = {
  timestamp?: InputMaybe<Scalars['String']>;
};

export type DemographicInput = {
  /** User's selected education */
  education?: InputMaybe<Scalars['String']>;
  /** User's selected gender */
  gender?: InputMaybe<Scalars['String']>;
  /** User's selected occupation */
  occupation?: InputMaybe<Scalars['String']>;
  /** User's selected referral source */
  referralSource?: InputMaybe<Scalars['String']>;
};

export type DependentSubscription = {
  __typename?: 'DependentSubscription';
  /** Limited Information about the subscription's group manager */
  groupManager?: Maybe<LimitedUser>;
  /** The ID for the subscription from the Payment Provider */
  id: Scalars['ID'];
  /** Flag set on the subscription to indicate that it is a dependent subscription */
  isDependent: Scalars['Boolean'];
  /** The user ID of the owner of the subscription */
  lumosUserId: Scalars['Int'];
  /** A unique integer that identifies the subscription */
  numericId: Scalars['Int'];
};

export type DependentSubscriptionHistory = {
  __typename?: 'DependentSubscriptionHistory';
  createdAt: Scalars['Float'];
  isActive: Scalars['Boolean'];
  /** The user ID of the owner of the group subscription */
  managerLumosUserId: Scalars['Int'];
  updatedAt: Scalars['Float'];
  /** The user ID of the dependent */
  userId: Scalars['Int'];
};

export type DisillusionInsight = {
  __typename?: 'DisillusionInsight';
  /** Number of accurate moves made by the user */
  accurateMoveCount: Scalars['Int'];
  /** User's last game play date for Disillusion */
  gamePlayDate: Scalars['BigInt'];
  /** Number of possible accurate moves */
  possibleAccurateMoveCount: Scalars['Int'];
  /** Number of possible steady moves */
  possibleSteadyMoveCount: Scalars['Int'];
  /** Number of possible strategic moves */
  possibleStrategicMoveCount: Scalars['Int'];
  /** Number of steady moves made by the user */
  steadyMoveCount: Scalars['Int'];
  /** Number of strategic moves made by the user */
  strategicMoveCount: Scalars['Int'];
};

export type EbbAndFlowInsight = {
  __typename?: 'EbbAndFlowInsight';
  gamePlayDate: Scalars['BigInt'];
  others: SpeedAccuracyScoreType;
  self: EbbAndFlowSelfInsight;
};

export type EbbAndFlowSelfInsight = {
  __typename?: 'EbbAndFlowSelfInsight';
  averageAccuracy: Scalars['Float'];
  averageSpeed: Scalars['Float'];
  speedAccuracyScore: Scalars['Float'];
};

export type ExternalIds = {
  __typename?: 'ExternalIds';
  apple: Scalars['String'];
  apple_free_trial: Scalars['String'];
  braintree: Scalars['String'];
  flagship?: Maybe<Scalars['String']>;
  flagship_activation_code?: Maybe<Scalars['String']>;
  flagship_adyen?: Maybe<Scalars['String']>;
  flagship_litle?: Maybe<Scalars['String']>;
  flagship_paypal?: Maybe<Scalars['String']>;
  flagship_paypal_remote?: Maybe<Scalars['String']>;
  flagship_unknown?: Maybe<Scalars['String']>;
  google: Scalars['String'];
  google_free_trial: Scalars['String'];
  stripe: Scalars['String'];
};

export type FitTest = {
  __typename?: 'FitTest';
  percentiles?: Maybe<Array<Maybe<GamePercentile>>>;
};

/** Represents a game identifier */
export type Game = {
  __typename?: 'Game';
  /** Area to which this game belongs. */
  areaSlug: Scalars['String'];
  /** Slug representing game. */
  slug: Scalars['String'];
};

export enum GameEngine {
  Cocos = 'COCOS',
  Cocos_3 = 'COCOS_3',
  Unity = 'UNITY',
  Unity_2019 = 'UNITY_2019'
}

export type GameInsight = {
  __typename?: 'GameInsight';
  consolidatedGamePlayCounts: Array<GamePlayCount>;
  /** Disillusion insights for the user */
  disillusion?: Maybe<DisillusionInsight>;
  /** Ebb and Flow insights for the user */
  ebbAndFlow?: Maybe<EbbAndFlowInsight>;
  /** Lost in Migration insights for the user */
  lostInMigration?: Maybe<LostInMigrationInsight>;
  /** Raindrops insights for the user */
  raindrops?: Maybe<RaindropsInsight>;
  /** Train of Thought insights for the user */
  trainOfThought?: Maybe<TrainOfThoughtInsight>;
};

export type GameLpi = {
  __typename?: 'GameLpi';
  game: Game;
  lpi?: Maybe<Scalars['Int']>;
};

/** Represents static details of a game. */
export type GameMetadata = {
  __typename?: 'GameMetadata';
  /** Area to which this game belongs. */
  areaSlug: Scalars['String'];
  /** Whether this game requires English fluency. */
  englishFluency: Scalars['Boolean'];
  /** Average time this game takes to play. */
  gameDurationMinutes: Scalars['Float'];
  /** Game rating... weight? */
  gameRatingWeight: Scalars['Float'];
  /** State of the game (e.g. - development, experimental, earlyAccess, normal) */
  gameState?: Maybe<Scalars['String']>;
  /** Slug representing game. */
  slug: Scalars['String'];
};

export type GamePercentile = {
  __typename?: 'GamePercentile';
  gamePlay: GamePlay;
  gameSlug: Scalars['String'];
  percentile: Scalars['Float'];
};

/** Represents an individual play of a game */
export type GamePlay = {
  __typename?: 'GamePlay';
  /** Engine on which game was played */
  engine: GameEngine;
  /** When game play was finished in epoch milliseconds, according to the client's clock */
  finishedAt: Scalars['Float'];
  game: Game;
  /** ID for correlating this gameplay with events logged throughout the user flow, including full game output */
  gameSessionId: Scalars['String'];
  /** Version of the played game */
  gameVersion: Scalars['String'];
  /** Server generated ID. */
  id: Scalars['BigInt'];
  /** Level that user achieved for this Gameplay. */
  levelNext: Scalars['Int'];
  /**
   * Highest level that the Game allowed the user to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelPrevious: Scalars['Int'];
  /**
   * Level that user selected to start at for this Gameplay.
   * Value is 0 if game does not have level syncing.
   */
  levelSelected: Scalars['Int'];
  /** The LPI for this particular GamePlay (based on the gameSlug, score, and platform) */
  lpi: Scalars['Int'];
  /** Platform on which game was played */
  platform: Platform;
  /** Player state object reported from this gameplay */
  playerState: Scalars['JSONObject'];
  /** Score resulting from this `GamePlay`. */
  score: Scalars['Int'];
  /** Current score's position relative to best score. */
  scorePosition: Scalars['Int'];
  /** Score version for the played game version */
  scoreVersion: Scalars['Int'];
  /** Stat resulting from this `GamePlay`. Could be null if the `Game` does not have a stat. */
  stat?: Maybe<Scalars['Int']>;
  /** Specific variant of the game played */
  variant: GameVariant;
};

export type GamePlayCount = {
  __typename?: 'GamePlayCount';
  gamePlayCount: Scalars['Int'];
  gameSlug: Scalars['String'];
  isAdditionalGameplayRequired: Scalars['Boolean'];
  requiredGamePlayCount: Scalars['Int'];
};

/** Generated upon completion of a game play */
export type GamePlayInput = {
  /** When game play was finished in epoch milliseconds, according to the client's clock */
  finishedAt: Scalars['Float'];
  /** Stringified output from game engine */
  gameOutput: Scalars['String'];
  /** ID for correlating this gameplay with events logged throughout the user flow */
  gameSessionId: Scalars['String'];
  /** Game this play relates to. */
  gameSlug: Scalars['String'];
  /** Optional Game variant, for example, a Fit Test (missing value indicates a regular game play) */
  variant?: InputMaybe<GameVariant>;
  /** Optional Unique identifier for the workout associated with this game play if this gameplay was associated with a workout */
  workoutId?: InputMaybe<Scalars['ID']>;
};

/** Represents the user's progress with respect to a particular game */
export type GameProgress = {
  __typename?: 'GameProgress';
  /**
   * Returns the `GamePlay` representing a `User`'s best stat for given `Platform` (via header) and `GameEngine`.
   * A `null` value signifies the `User` has never finished the `Game`.
   */
  bestStat?: Maybe<GamePlay>;
  /** `Game` data for this `GameProgress`. */
  game: Game;
  /**
   * The most recent play of this game for the current `Platform` and `GameEngine`.
   * A `null` value signifies the `User` has never played this `Game`.
   */
  lastGamePlay?: Maybe<GamePlay>;
  /**
   * Number of plays by a `User` for this [game](#game) to reach the next rank.
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  nextRankAt: Scalars['Int'];
  /**
   * `GameEngine` **and** `Platform` formatted stringified JSON (i.e. keys and values are specific to the `Platform` (via header) and `GameEngine`).
   * Can contain game-specific state (i.e. tower height or mute).
   * Includes canonical level data (`levelNext`).
   * Returns `null` if the `User` has never played this `Game` on any `Platform`.
   * Note that a non-null value is possible for a `Platform` a `User` has never played on due to canonical data (`level_next`). This can happen when a `User` plays on another platform.
   * # Example `UNITY_2021` Format for `contextual`
   * ```json
   * {
   *     "level_next": 1,
   *     "LumosLabs.Contextual.StoryView8": 1,
   *     "LumosLabs.Contextual.StoryView11": 1,
   *     "LumosLabs.Contextual.StoryView98": 1
   * }
   * ```
   * # Example `COCOS` Format for `contextual`
   * ```json
   * {
   *     "user_level": 1,
   *     "savedUserData": {
   *         "recentShelves": [14],
   *         "previousViews": {
   *             "35": 1
   *         },
   *         "readingComp": 40
   *     }
   * }
   * ```
   */
  playerState?: Maybe<Scalars['JSONObject']>;
  /**
   * The number of game plays a user has completed after achieving their current rank.
   * For example the rank newcomer is achieved after completing 3 gameplays and the next rank is achieved after 8 game plays. So if a user has 5 gameplays, plays at rank is calculated as `5 - 3`.
   */
  playsAtRank: Scalars['Int'];
  /**
   * String representation of a `User`'s current rank for this [game](#game).
   * Applies across all `Platform`s and `GameEngine`s (i.e. canonical).
   */
  rank: Scalars['String'];
  /**
   * `GamePlay`s ordered by score (highest to lowest) for given `Platform` (via header) and `GameEngine`.
   * An empty array signifies the `User` has never finished the `Game`. Results are limited to 100.
   */
  topScores: Array<Maybe<GamePlay>>;
  /**
   * `GamePlay`s ordered by stat (highest to lowest) for given `Platform` (via header) and `GameEngine`.
   * An empty array signifies the `User` has never finished the `Game`. Results are limited to 2.
   */
  topStats: Array<Maybe<GamePlay>>;
  /**
   * Total number of plays by a `User` for this [game](#game) across all `Platform`s and `GameEngine`s (i.e. canonical).
   * Primarily used for ranking purposes.
   */
  totalPlays: Scalars['Int'];
};


/** Represents the user's progress with respect to a particular game */
export type GameProgressPlayerStateArgs = {
  engine?: InputMaybe<GameEngine>;
};


/** Represents the user's progress with respect to a particular game */
export type GameProgressTopScoresArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type GameUnlock = {
  __typename?: 'GameUnlock';
  /** LocalDate game unlock ends (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  gameSlug: Scalars['String'];
  /** LocalDate game unlock starts (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export type GameUnlockInput = {
  /** LocalDate game unlock ends (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  /** Game this play relates to. */
  gameSlug: Scalars['String'];
  /** LocalDate game unlock starts (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export enum GameVariant {
  FitTest = 'FitTest',
  Regular = 'Regular'
}

export type GetOrMigrateUserInput = {
  birthdate?: InputMaybe<Scalars['String']>;
  demographics?: InputMaybe<DemographicInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type GoogleSubscription = {
  __typename?: 'GoogleSubscription';
  latestOrderId: Scalars['String'];
};

export type GoogleSubscriptionInput = {
  currency: Scalars['String'];
  introPrice: Scalars['Float'];
  packageName: Scalars['String'];
  planPrice: Scalars['Float'];
  purchaseToken: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  groupManager: LimitedUser;
  subscriptionNumericId: Scalars['Int'];
};

export type LevelNextForGame = {
  __typename?: 'LevelNextForGame';
  gameSlug?: Maybe<Scalars['String']>;
  levelNext?: Maybe<Scalars['Int']>;
};

export type LimitedUser = {
  __typename?: 'LimitedUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type LostInMigrationInsight = {
  __typename?: 'LostInMigrationInsight';
  filteringStyle: Scalars['String'];
  gamePlayDate: Scalars['BigInt'];
  others: LostInMigrationUserInsight;
  self: LostInMigrationUserInsight;
};

export type LostInMigrationUserInsight = {
  __typename?: 'LostInMigrationUserInsight';
  matchingFlocks: Scalars['Float'];
  nonMatchingFlocks: Scalars['Float'];
  slowDownPercentage: Scalars['Int'];
};

export type LpiSummary = {
  __typename?: 'LpiSummary';
  ageCohortComparison: AgeCohortComparison;
  bestOverallLpi?: Maybe<Scalars['Int']>;
  /** Get the user's LPIs for (optionally) a specific game or all games from start of the time to render the LPI chart */
  consolidatedGameDatedLpi: Array<ConsolidatedGameDatedLpi>;
  lpisByArea: Array<AreaLpi>;
  /** Returns a list of `object` containing `gameSlug` and `lpi`. */
  lpisByGame: Array<GameLpi>;
  /**
   * Returns a list of `object` containing `gameSlug`,
   * `lpiIncrease` (Difference of first gamePlay LPI to latest cummulative LPI),
   * `currentLpi` and `bucket` (LPI Improvement bucket).
   * `bucket` is calculated based on the `lpiIncrease` value and is present here
   * # Example
   * ```json
   *   [{
   *     "gameSlug": "train-of-thought",
   *     "lpiIncrease": 100,
   *     "bucket": "bucket_4"
   *   }] | null
   * ```
   */
  mostImprovedGames: Array<MostImprovedGames>;
  overallLpi?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Float'];
};


export type LpiSummaryConsolidatedGameDatedLpiArgs = {
  gameSlug?: InputMaybe<Scalars['String']>;
};


export type LpiSummaryLpisByGameArgs = {
  games?: InputMaybe<Array<VersionedGame>>;
};


export type LpiSummaryMostImprovedGamesArgs = {
  games?: InputMaybe<Array<VersionedGame>>;
};

export type LumosSubscription = {
  __typename?: 'LumosSubscription';
  /** Activation code redeemed for the subscription */
  activationCode?: Maybe<Scalars['String']>;
  /** Number of milliseconds since the epoch that the activation code was redeemed */
  activationCodeRedeemedAt?: Maybe<Scalars['BigInt']>;
  /** Whether this subscription will autorenew */
  autoRenews: Scalars['Boolean'];
  /** Billing frequency associated with the plan that the user purchased */
  billingFrequency: BillingFrequency;
  /** The currency in which the purchase was made */
  currency: Scalars['String'];
  /** number of milliseconds since the epoch when the subscription ends, unless renewed */
  currentPeriodEnd: Scalars['BigInt'];
  /** number of milliseconds since the epoch that the subscription started */
  currentPeriodStart: Scalars['BigInt'];
  /** Members on a group subscription, if applicable */
  dependentUsers: Array<LimitedUser>;
  /** Whether this subscription can be upgraded to another plan of greater value */
  eligibleForUpgrade: Scalars['Boolean'];
  /** The ID associated with the subscription in the external payment provider */
  externalId: Scalars['String'];
  /** The ids used for this subscription's plan for each provider */
  externalIds: ExternalIds;
  /** The Google Play Order ID associated with the subscription, if applicable */
  googlePlayOrderId?: Maybe<Scalars['String']>;
  /** The ID for the subscription from the Payment Provider, or for Lifetime, the Stripe payment_intent id or the transaction ID for Braintree */
  id: Scalars['ID'];
  /** The price paid when the initial purchase was made */
  introPrice: Scalars['Float'];
  /** The UUID invitation id to use for family plan invitations, if applicable */
  invitationId?: Maybe<Scalars['String']>;
  /** Whether this subscription can be renewed (not whether it will renew, which is the autoRenews property) */
  isRenewable: Scalars['Boolean'];
  /** A unique human readable identifier for the subscription */
  lookupId: Scalars['String'];
  /** The user ID of the owner of the subscription */
  lumosUserId: Scalars['Int'];
  /** The maximum number of dependents this subscription is allowed to have */
  maximumDependents: Scalars['Int'];
  /** A unique integer that identifies the subscription */
  numericId: Scalars['Int'];
  /** The full price of the subscription for subsequent charges, if applicable */
  planPrice: Scalars['Float'];
  /** Type of plan that the user purchased */
  planType: PlanType;
  /** The id of a promotion applied to the subscription, if applicable */
  promotionId?: Maybe<Scalars['String']>;
  /** The payment processor -- Stripe, Braintree, Apple, or Google */
  provider: PlanProvider;
  /** Whether to send advance billing notifications or not */
  send_advance_billing_notification: Scalars['Boolean'];
  /** Sku associated with the plan that the user purchased */
  sku: Sku;
  /** Status of the subscription (active / some form of inactive) */
  status: SubscriptionStatus;
  /** The transactions associated with the subscription */
  transactions: Array<Transaction>;
};

export type LumosSubscriptionId = {
  __typename?: 'LumosSubscriptionId';
  id: Scalars['String'];
};

export type LumosSubscriptionInput = {
  provider?: InputMaybe<PlanProvider>;
  subscriptionId: Scalars['String'];
};

export type MarketingPreferences = {
  __typename?: 'MarketingPreferences';
  emailNotifications: MarketingPreferencesEmailNotifications;
};

export type MarketingPreferencesEmailNotifications = {
  __typename?: 'MarketingPreferencesEmailNotifications';
  newGamesAndUpdates: Scalars['Boolean'];
  newsletters: Scalars['Boolean'];
  specialOffers: Scalars['Boolean'];
};

export type MostImprovedGames = {
  __typename?: 'MostImprovedGames';
  /** Bucket logic is based on the increasedLpi value, as mentioned in LPI_IMPROVEMENT_BUCKET in types.ts file */
  bucket: Scalars['String'];
  /** Game for which the LPI is calculated */
  game: Game;
  /** Difference of first gamePlay LPI to latest cummulative LPI */
  lpiIncrease: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateStripeSubscription: LumosSubscriptionId;
  addAppleTransaction: Scalars['Boolean'];
  /** Adds game play for a User with raw Cocos 3 game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addCocosGamePlay: GamePlay;
  addDependentUserToSubscription: Scalars['Boolean'];
  /** Adds a game unlock */
  addGameUnlockEntry: GameUnlock;
  /** Adds an admin note associated with a user account, returning ID of the created note */
  addNote: Scalars['Int'];
  /** Adds game play for a User with raw Unity game output. Returns GUID for the `GamePlay` as acknowledgement. */
  addUnityGamePlay: GamePlay;
  /** Mutation used by cron job to delete user data asynchronously */
  anonymizeUserData?: Maybe<Scalars['Boolean']>;
  cancelSubscription: LumosSubscriptionId;
  /** Cascade flagship account deletion */
  cascadeFlagshipAccountDeletion: Scalars['Boolean'];
  checkDeferredSubscription: LumosSubscriptionId;
  createBraintreeSubscription: LumosSubscriptionId;
  createGoogleSubscription: GoogleSubscription;
  /** Creates a record of a workout in the database of a workout created on mobile while the client is offline */
  createOfflineWorkout: Scalars['ID'];
  createPromotion: Promotion;
  createStripeCheckoutSession: StripeCheckoutSession;
  createStripeCustomCheckoutSession: StripeCustomCheckoutSession;
  /** Add User */
  createUser: User;
  /** Delete underage users' account */
  deleteUnderageUsers?: Maybe<Scalars['Boolean']>;
  expireStaleFraudRiskSubscriptions: Scalars['Boolean'];
  /** Add a user if they don't exist */
  getOrMigrateUser: User;
  /** Mark user as migrated to Ory */
  markAsMigratedToKratos: User;
  recordAppleWebhookNotification: Scalars['String'];
  refundTransactions: Scalars['Boolean'];
  removeDependentUserFromSubscription: Scalars['Boolean'];
  /** Repair a user's streak by joining the last two streaks */
  repairLastStreak: Streak;
  /** Request Account Deletion */
  requestAccountDeletion: Scalars['Boolean'];
  /** Set user training reminders active or inactive */
  setReminderScheduleActive: ReminderSchedule;
  syncAppleSubscription: AppleSubscription;
  updateAutoRenewalStatus: LumosSubscriptionId;
  /** Updates a user's birthdate */
  updateBirthdate: User;
  /** Adds user input from the demographic onboarding survey */
  updateDemographics: User;
  /** Updates a user's email in their profile */
  updateEmail: User;
  updateGameLevel: LevelNextForGame;
  /** Updates a user's last used language */
  updateLastUsedLanguage: Scalars['Boolean'];
  updateLumosSubscription: LumosSubscriptionId;
  /** Updates a user's marketing preferences */
  updateMarketingPreferences: User;
  /** Updates a user's name in their profile */
  updateName: User;
  /** Updates a user's language fluency games preference in their profile */
  updateNoEnglishFluencyGames: User;
  updatePromotionStatus: Promotion;
  /** Set user training reminder schedule */
  updateReminderSchedule: ReminderSchedule;
  /** Updates a user's roles */
  updateRoles: User;
  /** Adds user input from the demographic onboarding survey */
  updateUserDemographics: User;
  /** Update a user's segmentation */
  updateUserSegmentation: User;
  /** Updates the list of games in a Workout. Returns the new workout games list */
  updateWorkoutGame: Workout;
};


export type MutationActivateStripeSubscriptionArgs = {
  input: StripeSessionInput;
};


export type MutationAddAppleTransactionArgs = {
  input: AppleTransactionInput;
};


export type MutationAddCocosGamePlayArgs = {
  input: GamePlayInput;
};


export type MutationAddDependentUserToSubscriptionArgs = {
  input: AddDependentUserToSubscriptionInput;
};


export type MutationAddGameUnlockEntryArgs = {
  input: GameUnlockInput;
};


export type MutationAddNoteArgs = {
  input: UserNoteInput;
};


export type MutationAddUnityGamePlayArgs = {
  input: GamePlayInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCascadeFlagshipAccountDeletionArgs = {
  userId: Scalars['Int'];
};


export type MutationCheckDeferredSubscriptionArgs = {
  input: CheckDeferredSubscriptionInput;
};


export type MutationCreateBraintreeSubscriptionArgs = {
  input: BraintreeSubscriptionInput;
};


export type MutationCreateGoogleSubscriptionArgs = {
  input: GoogleSubscriptionInput;
};


export type MutationCreateOfflineWorkoutArgs = {
  input: OfflineWorkoutInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  input: StripeCheckoutSessionInput;
};


export type MutationCreateStripeCustomCheckoutSessionArgs = {
  input: StripeCustomCheckoutSessionInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteUnderageUsersArgs = {
  input?: InputMaybe<DeleteUnderageUsersInput>;
};


export type MutationGetOrMigrateUserArgs = {
  input: GetOrMigrateUserInput;
};


export type MutationMarkAsMigratedToKratosArgs = {
  userId: Scalars['Int'];
};


export type MutationRecordAppleWebhookNotificationArgs = {
  input: RecordAppleWebhookNotificationInput;
};


export type MutationRefundTransactionsArgs = {
  input: RefundTransactionsInput;
};


export type MutationRemoveDependentUserFromSubscriptionArgs = {
  input: RemoveDependentUserFromSubscriptionInput;
};


export type MutationRepairLastStreakArgs = {
  userId: Scalars['Int'];
};


export type MutationRequestAccountDeletionArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationSetReminderScheduleActiveArgs = {
  input: SetReminderScheduleActiveInput;
};


export type MutationSyncAppleSubscriptionArgs = {
  input: AppleSubscriptionInput;
};


export type MutationUpdateAutoRenewalStatusArgs = {
  input: UpdateAutoRenewalStatusInput;
};


export type MutationUpdateBirthdateArgs = {
  input: UpdateBirthdateInput;
};


export type MutationUpdateDemographicsArgs = {
  input: DemographicInput;
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateGameLevelArgs = {
  gameSlug: Scalars['String'];
  levelNext: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationUpdateLastUsedLanguageArgs = {
  lastUsedLanguage: Scalars['String'];
};


export type MutationUpdateLumosSubscriptionArgs = {
  input: UpdateLumosSubscriptionInput;
};


export type MutationUpdateMarketingPreferencesArgs = {
  input: UpdateMarketingPreferencesInput;
};


export type MutationUpdateNameArgs = {
  input: UpdateNameInput;
};


export type MutationUpdateNoEnglishFluencyGamesArgs = {
  input: UpdateNoEnglishFluencyGamesInput;
};


export type MutationUpdatePromotionStatusArgs = {
  isActive: Scalars['Boolean'];
  promotionCode: Scalars['String'];
};


export type MutationUpdateReminderScheduleArgs = {
  input: UpdateReminderScheduleInput;
};


export type MutationUpdateRolesArgs = {
  roles: Array<Scalars['String']>;
  userId: Scalars['Int'];
};


export type MutationUpdateUserDemographicsArgs = {
  input: DemographicInput;
  userId: Scalars['Int'];
};


export type MutationUpdateUserSegmentationArgs = {
  segmentation: Scalars['JSONObject'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateWorkoutGameArgs = {
  newGameSlug: Scalars['String'];
  workoutId: Scalars['ID'];
  workoutSlot: Scalars['Int'];
};

export type OfflineWorkoutInput = {
  /** Algorithm version that generated the workout */
  algorithmVersion?: InputMaybe<Scalars['String']>;
  /** Timestamp for when the offline workout was created on the client, in epoch milliseconds (UTC). */
  createdAt: Scalars['Float'];
  /** Array of game slugs in the workout */
  gameSlugs: Array<Scalars['String']>;
  /** Unique identifier for the workout created while offline */
  workoutId: Scalars['ID'];
};

export type PaymentHistoryLinkDetails = {
  __typename?: 'PaymentHistoryLinkDetails';
  agreementCreatedAt?: Maybe<Scalars['Float']>;
  provider: PlanProvider;
  url: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  allowPayPal: Scalars['Boolean'];
  billingFrequency: BillingFrequency;
  externalIds: ExternalIds;
  isRenewable: Scalars['Boolean'];
  planType: PlanType;
  price: PlanPrice;
  sku: Sku;
  stripePriceId: Scalars['String'];
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  /** amount is generic across all providers, and FE clients don't need to divide by stripeMinorUnitDivisor, it is already in dollars */
  amount: Scalars['Float'];
  currency: Scalars['Currency'];
  /** For strip plans, the divisor should be used to convert the amount for display, example amount / stripeMinorUnitDivisor, to get the amount in dollars instead of cents. This is used in Web for display purposes. */
  stripeMinorUnitDivisor: Scalars['Int'];
};

export enum PlanProvider {
  Apple = 'apple',
  Braintree = 'braintree',
  Flagship = 'flagship',
  FlagshipActivationCode = 'flagship_activation_code',
  FlagshipAdyen = 'flagship_adyen',
  FlagshipLitle = 'flagship_litle',
  FlagshipPaypal = 'flagship_paypal',
  FlagshipPaypalRemote = 'flagship_paypal_remote',
  FlagshipUnknown = 'flagship_unknown',
  Google = 'google',
  Lumos = 'lumos',
  Stripe = 'stripe'
}

export enum PlanType {
  Family = 'family',
  Individual = 'individual'
}

export enum Platform {
  Mobile = 'mobile',
  Web = 'web'
}

export type Promotion = {
  __typename?: 'Promotion';
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  percentageOff: Scalars['Float'];
  promotionCode: Scalars['String'];
  updatedAt: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  GetCurrentUser?: Maybe<CurrentUser>;
  /** Fetch user by email */
  findUserByEmail?: Maybe<User>;
  /** Fetch all games */
  games: Array<GameMetadata>;
  /** Fetch unlocked games */
  gamesUnlocked: Array<GameUnlock>;
  getAllLumosSubscriptionsForUser: Array<LumosSubscription>;
  getAllPromotions: Array<Promotion>;
  getDependentSubscriptionHistoryForUser: Array<DependentSubscriptionHistory>;
  getInvitation: Invitation;
  getLumosSubscriptionByLookupId: LumosSubscription;
  getPaymentHistoryLinkDetails: Array<PaymentHistoryLinkDetails>;
  /** @deprecated Use the User.getPromotion field instead */
  getPromotion: Promotion;
  getStripePaymentMethodUpdateLink: Scalars['String'];
  /** Fetch user by login_token */
  getUserByLoginToken?: Maybe<User>;
  /** JWT token to authorize user on Help Center(Zendesk) platform */
  helpCenterJWT: Scalars['String'];
  /** Is the current app version outdated. Available to unauthenticated clients */
  isUpgradeRequired: Scalars['Boolean'];
  /** Fetch info about a user, optionally pass in the userId for Admin requests */
  me: User;
  /** Fetch user profile admin notes */
  notes: Array<UserNotes>;
  /** Fetch user training reminder schedule */
  reminderSchedule: ReminderSchedule;
  retrieveAppleNotificationHistory: AppleNotificationHistoryResponse;
  retrieveBraintreeClientToken: BraintreeClientTokenResponse;
  retrieveLumosSubscription: LumosSubscription;
  retrievePlans: Array<Plan>;
  /** Fetch user IDs by email match */
  userIdsByMatchingEmail?: Maybe<Array<Maybe<User>>>;
  /** Education options */
  validEducation: Array<Scalars['String']>;
  /** Gender options */
  validGenders: Array<Scalars['String']>;
  /** Occupation options */
  validOccupations: Array<Scalars['String']>;
  /** Referral Source options */
  validReferralSources: Array<Scalars['String']>;
};


export type QueryFindUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGamesUnlockedArgs = {
  availableOnDate?: InputMaybe<Scalars['LocalDate']>;
};


export type QueryGetAllLumosSubscriptionsForUserArgs = {
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDependentSubscriptionHistoryForUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetInvitationArgs = {
  invitationID: Scalars['String'];
};


export type QueryGetLumosSubscriptionByLookupIdArgs = {
  lookupId: Scalars['String'];
};


export type QueryGetPaymentHistoryLinkDetailsArgs = {
  headline: Scalars['String'];
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPromotionArgs = {
  promotionCode: Scalars['String'];
  promotionSendDate?: InputMaybe<Scalars['LocalDate']>;
};


export type QueryGetStripePaymentMethodUpdateLinkArgs = {
  headline: Scalars['String'];
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserByLoginTokenArgs = {
  loginToken: Scalars['String'];
};


export type QueryIsUpgradeRequiredArgs = {
  platform: AppPlatform;
  version: Scalars['SemVer'];
};


export type QueryMeArgs = {
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryNotesArgs = {
  userId: Scalars['Int'];
};


export type QueryReminderScheduleArgs = {
  userIdOverride?: InputMaybe<Scalars['Int']>;
};


export type QueryRetrieveAppleNotificationHistoryArgs = {
  input: AppleNotificationHistoryInput;
};


export type QueryRetrieveLumosSubscriptionArgs = {
  input: LumosSubscriptionInput;
};


export type QueryRetrievePlansArgs = {
  currency?: InputMaybe<Scalars['String']>;
  planSetName?: InputMaybe<Scalars['String']>;
  skus?: InputMaybe<Array<Sku>>;
};


export type QueryUserIdsByMatchingEmailArgs = {
  email: Scalars['String'];
};

export type RaindropsExpression = {
  __typename?: 'RaindropsExpression';
  /** Answer for the expression */
  answer: Scalars['Int'];
  /** Category for the expression */
  category: Scalars['String'];
  /** Difficulty level for the expression, can be easier, medium, harder */
  difficulty: Scalars['String'];
  /** Expression for the raindrops game */
  expression: Scalars['String'];
  /** LHS Operand for the expression */
  operandLHS: Scalars['Int'];
  /** RHS Operand for the expression */
  operandRHS: Scalars['Int'];
  /** Operator for the expression */
  operator: Scalars['String'];
};

export type RaindropsInsight = {
  __typename?: 'RaindropsInsight';
  /** User's last game play date for Raindrops */
  gamePlayDate: Scalars['BigInt'];
  /** List of missed expressions for Raindrops */
  missedExpressions: Array<RaindropsExpression>;
};

export type RecordAppleWebhookNotificationInput = {
  notificationId: Scalars['String'];
  payload: Scalars['String'];
  signedDate: Scalars['Float'];
};

export type RefundTransactionsInput = {
  provider: PlanProvider;
  transactionIds: Array<Scalars['String']>;
};

export type ReminderSchedule = {
  __typename?: 'ReminderSchedule';
  active: Scalars['Boolean'];
  days: Array<DayOfWeek>;
  timeSlot: ReminderTimeSlot;
};

export enum ReminderTimeSlot {
  Afternoon = 'afternoon',
  Evening = 'evening',
  Morning = 'morning'
}

export type RemoveDependentUserFromSubscriptionInput = {
  dependentUserId: Scalars['Int'];
  subscriptionNumericId: Scalars['Int'];
};

export enum Sku {
  FamilyAnnual = 'family_annual',
  FamilyBiennial = 'family_biennial',
  FamilyMonthly = 'family_monthly',
  IndividualAnnual = 'individual_annual',
  IndividualBiennial = 'individual_biennial',
  IndividualLifetime = 'individual_lifetime',
  IndividualMonthly = 'individual_monthly',
  IndividualOneTime = 'individual_one_time'
}

export type SetReminderScheduleActiveInput = {
  active: Scalars['Boolean'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type SpeedAccuracyScoreType = {
  __typename?: 'SpeedAccuracyScoreType';
  speedAccuracyScore: Scalars['Float'];
};

export type Streak = {
  __typename?: 'Streak';
  /** LocalDate streak ended (YYYY-MM-DD) */
  endDate: Scalars['LocalDate'];
  /** Length of streak in days. */
  length: Scalars['Int'];
  /** LocalDate streak began (YYYY-MM-DD) */
  startDate: Scalars['LocalDate'];
};

export type StreakHistory = {
  __typename?: 'StreakHistory';
  /** All time longest streak for user. */
  bestStreak?: Maybe<Streak>;
  /** Collection of streaks. Defaults to all-time history. Optional date range may be provided. */
  streaks: Array<Streak>;
};


export type StreakHistoryStreaksArgs = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type StripeCheckoutSessionInput = {
  cancelUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  promotionId?: InputMaybe<Scalars['String']>;
  sku: Sku;
  stripePriceId: Scalars['String'];
  successUrl: Scalars['String'];
  termsText: Scalars['String'];
};

export type StripeCustomCheckoutSession = {
  __typename?: 'StripeCustomCheckoutSession';
  clientSecret: Scalars['String'];
  id: Scalars['String'];
};

export type StripeCustomCheckoutSessionInput = {
  email: Scalars['String'];
  promotionId?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  sku: Sku;
  stripePriceId: Scalars['String'];
};

export type StripeSessionInput = {
  sessionId: Scalars['String'];
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Deferred = 'deferred',
  Expired = 'expired',
  ExpiredDeferred = 'expired_deferred',
  FreeTrial = 'free_trial',
  PastDue = 'past_due'
}

export type SubscriptionUpdateFields = {
  autoRenews?: InputMaybe<Scalars['Boolean']>;
  currentPeriodEnd?: InputMaybe<Scalars['Float']>;
  currentPeriodStart?: InputMaybe<Scalars['Float']>;
  eligibleForUpgrade?: InputMaybe<Scalars['Boolean']>;
  googleOrderId?: InputMaybe<Scalars['String']>;
  sendAdvanceBillingNotification?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SubscriptionStatus>;
};

export type TrainOfThoughtInsight = {
  __typename?: 'TrainOfThoughtInsight';
  /** User's average level for Train of Thought, can be above_average, at_average, or below_average */
  averageLevel: Scalars['String'];
  /** User's last game play date for Train of Thought */
  gamePlayDate: Scalars['BigInt'];
  /** Average user's insights for Train of Thought */
  others: TrainOfThoughtUserInsight;
  /** User's rank for Train of Thought, can be station_agent, dispatcher, conductor, master_conductor */
  planningRank: Scalars['String'];
  /** User's percentile for Train of Thought */
  planningRankPercentile: Scalars['Float'];
  /** User's insight for Train of Thought */
  self: TrainOfThoughtUserInsight;
  /** User's level for Train of Thought, ranges from 2 to 14 */
  userLevel: Scalars['Int'];
};

export type TrainOfThoughtUserInsight = {
  __typename?: 'TrainOfThoughtUserInsight';
  /** Percentage of close calls by the user */
  closeCallPercentage: Scalars['Float'];
  /** Total number of close calls by the user */
  closeCalls: Scalars['Int'];
  /** Percentage of calls missed by the user */
  missedCallPercentage: Scalars['Float'];
  /** Total number of calls missed by the user */
  missedCalls: Scalars['Int'];
  /** Percentage of early calls by the user */
  plannedEarlyCallPercentage: Scalars['Float'];
  /** Total number of early calls by the user */
  plannedEarlyCalls: Scalars['Int'];
};

export type Transaction = {
  __typename?: 'Transaction';
  /** A fixed-point decimal representation of the amount of the transaction */
  amount: Scalars['Float'];
  createdAt: Scalars['Float'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  refunded: Scalars['Boolean'];
};

export type UiSettings = {
  __typename?: 'UISettings';
  game_sound_on?: Maybe<Scalars['Boolean']>;
  showSideNav?: Maybe<Scalars['Boolean']>;
};

export type UpdateAutoRenewalStatusInput = {
  autoRenews: Scalars['Boolean'];
  provider: PlanProvider;
  subscriptionId: Scalars['String'];
};

export type UpdateBirthdateInput = {
  birthdate: Scalars['LocalDate'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateLumosSubscriptionInput = {
  fields: SubscriptionUpdateFields;
  provider: PlanProvider;
  subscriptionId: Scalars['String'];
};

export type UpdateMarketingPreferencesInput = {
  emailNewGamesAndUpdates: Scalars['Boolean'];
  emailNewsletters: Scalars['Boolean'];
  emailSpecialOffers: Scalars['Boolean'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateNameInput = {
  name: Scalars['String'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateNoEnglishFluencyGamesInput = {
  noEnglishFluencyGames: Scalars['Boolean'];
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type UpdateReminderScheduleInput = {
  days: Array<DayOfWeek>;
  timeSlot: ReminderTimeSlot;
  userIdToUpdate?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  ageCohort: Scalars['String'];
  ageCohortSlug: Scalars['String'];
  birthdate?: Maybe<Scalars['LocalDate']>;
  createdAt: Scalars['Float'];
  /** Get a list of a user's cumulative results per game across all plays */
  cumulativeGameResults: Array<Maybe<CumulativeGameResult>>;
  education?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** Get the Fit Test results for a user */
  fitTest: FitTest;
  /** Get the game insights for the user , the game insights will be different for each game */
  gameInsight: GameInsight;
  /** Get a list of all user's game plays */
  gamePlays: Array<Maybe<GamePlay>>;
  /**
   * The `GameProgress` for given `Game`s for this `User`.
   * Further filtered by given parameters (`gameSlug`, `engine` and `version`).
   * Platform is inferred by the `platform` header.
   */
  gamesProgress: Array<GameProgress>;
  gender?: Maybe<Scalars['String']>;
  /** Get workouts of the day which are available for the user */
  getAvailableWorkouts?: Maybe<Array<Maybe<Workout>>>;
  getPromotion: Promotion;
  hasPremium: Scalars['Boolean'];
  id: Scalars['Int'];
  isFreeTrialEligible: Scalars['Boolean'];
  /**
   * Get the levelNext for all games for the user, which is saved after a gameplay in user_game_data_cross_platform
   * and will be the level they start the next game with
   */
  levelNextForAllGames: Array<Maybe<LevelNextForGame>>;
  loginToken?: Maybe<Scalars['String']>;
  lpiSummary: LpiSummary;
  marketingPreferences: MarketingPreferences;
  migratedAt?: Maybe<Scalars['Float']>;
  migratedToKratos: Scalars['Boolean'];
  noEnglishFluencyGames: Scalars['Boolean'];
  occupation?: Maybe<Scalars['String']>;
  originL2?: Maybe<Scalars['Boolean']>;
  referralSource?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  segmentation?: Maybe<Scalars['JSONObject']>;
  streakHistory: StreakHistory;
  /** Returns a LumosSubscription or DependentSubscription if they are part of or have an active subscription, otherwise null */
  subscription?: Maybe<UserSubscription>;
  /**
   * Get a user's workout of the day, creating a new workout if necessary, or modifying the existing workout if it
   * contains unknown or otherwise ineligible games
   */
  todaysWorkout: Workout;
  trainingAreaPreferences: Array<Scalars['String']>;
  updatedAt: Scalars['Float'];
};


export type UserGamesProgressArgs = {
  games?: InputMaybe<Array<VersionedGame>>;
};


export type UserGetPromotionArgs = {
  promotionCode?: InputMaybe<Scalars['String']>;
  promotionSendDate?: InputMaybe<Scalars['LocalDate']>;
};


export type UserStreakHistoryArgs = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};


export type UserTodaysWorkoutArgs = {
  knownGameSlugs: Array<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
};

export type UserNoteInput = {
  /** Text of the note, provided by admin */
  noteText: Scalars['String'];
  /** User Id */
  userId: Scalars['Int'];
};

export type UserNotes = {
  __typename?: 'UserNotes';
  adminAuthor: Scalars['String'];
  createdAt: Scalars['Float'];
  noteText: Scalars['String'];
  noteType: Scalars['String'];
};

export type UserSubscription = DependentSubscription | LumosSubscription;

export type VersionedGame = {
  /**
   * Canonical game slugs, game UUIDs (e.g. `train-of-thought`, `color-match` or `river-ranger`).
   * Previously known as master game slug on flagship.
   */
  gameSlug: Scalars['String'];
  /** Semantic game version, e.g. 4.1.3 /d+.d+.d+/ */
  gameVersion: Scalars['String'];
};

export type Workout = {
  __typename?: 'Workout';
  algorithmVersion?: Maybe<Scalars['String']>;
  /** When this `Workout` was last changed (inclusive of `workoutGames`) */
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  mode: Scalars['String'];
  slots: WorkoutSlots;
  /** Ordered list of recommended `Game`s for this `Workout` */
  workoutGames: Array<WorkoutGame>;
};

export type WorkoutGame = {
  __typename?: 'WorkoutGame';
  game: Game;
  /**
   * Last time `Game` was played as part of parent `Workout`.
   * `null` indicates the `Game` was never played for parent `Workout`.
   * Note that this `Game` could have been played independently of parent `Workout` (i.e. Games tab).
   */
  lastGamePlay?: Maybe<GamePlay>;
};

export type WorkoutSlots = {
  __typename?: 'WorkoutSlots';
  slot1: WorkoutGame;
  slot2: WorkoutGame;
  slot3: WorkoutGame;
  slot4: WorkoutGame;
  slot5: WorkoutGame;
};

export type AddCocosGamePlayMutationVariables = Exact<{
  input: GamePlayInput;
}>;


export type AddCocosGamePlayMutation = { __typename?: 'Mutation', addCocosGamePlay: { __typename?: 'GamePlay', id: number } };

export type AddDependentUserToSubscriptionMutationVariables = Exact<{
  input: AddDependentUserToSubscriptionInput;
}>;


export type AddDependentUserToSubscriptionMutation = { __typename?: 'Mutation', addDependentUserToSubscription: boolean };

export type AddUnityGamePlayMutationVariables = Exact<{
  input: GamePlayInput;
}>;


export type AddUnityGamePlayMutation = { __typename?: 'Mutation', addUnityGamePlay: { __typename?: 'GamePlay', id: number } };

export type CheckDeferredSubscriptionMutationVariables = Exact<{
  input: CheckDeferredSubscriptionInput;
}>;


export type CheckDeferredSubscriptionMutation = { __typename?: 'Mutation', checkDeferredSubscription: { __typename?: 'LumosSubscriptionId', id: string } };

export type CreateBraintreeSubscriptionMutationVariables = Exact<{
  input: BraintreeSubscriptionInput;
}>;


export type CreateBraintreeSubscriptionMutation = { __typename?: 'Mutation', createBraintreeSubscription: { __typename?: 'LumosSubscriptionId', id: string } };

export type RaindropInsightQueryVariables = Exact<{ [key: string]: never; }>;


export type RaindropInsightQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', raindrops?: { __typename?: 'RaindropsInsight', gamePlayDate: number, missedExpressions: Array<{ __typename?: 'RaindropsExpression', answer: number, category: string, difficulty: string, expression: string, operandLHS: number, operandRHS: number, operator: string }> } | null } } };

export type ConsolidatedGamePlayCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsolidatedGamePlayCountsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', consolidatedGamePlayCounts: Array<{ __typename?: 'GamePlayCount', gamePlayCount: number, gameSlug: string, isAdditionalGameplayRequired: boolean, requiredGamePlayCount: number }> } } };

export type TrainOfThoughtInsightQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainOfThoughtInsightQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', trainOfThought?: { __typename?: 'TrainOfThoughtInsight', averageLevel: string, gamePlayDate: number, planningRank: string, planningRankPercentile: number, userLevel: number, others: { __typename?: 'TrainOfThoughtUserInsight', closeCallPercentage: number, closeCalls: number, missedCallPercentage: number, missedCalls: number, plannedEarlyCallPercentage: number, plannedEarlyCalls: number }, self: { __typename?: 'TrainOfThoughtUserInsight', closeCallPercentage: number, closeCalls: number, missedCallPercentage: number, missedCalls: number, plannedEarlyCallPercentage: number, plannedEarlyCalls: number } } | null } } };

export type LostInMigrationInsightQueryVariables = Exact<{ [key: string]: never; }>;


export type LostInMigrationInsightQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', lostInMigration?: { __typename?: 'LostInMigrationInsight', filteringStyle: string, gamePlayDate: number, others: { __typename?: 'LostInMigrationUserInsight', matchingFlocks: number, nonMatchingFlocks: number, slowDownPercentage: number }, self: { __typename?: 'LostInMigrationUserInsight', matchingFlocks: number, nonMatchingFlocks: number, slowDownPercentage: number } } | null } } };

export type EbbAndFlowInsightsQueryVariables = Exact<{ [key: string]: never; }>;


export type EbbAndFlowInsightsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', ebbAndFlow?: { __typename?: 'EbbAndFlowInsight', gamePlayDate: number, others: { __typename?: 'SpeedAccuracyScoreType', speedAccuracyScore: number }, self: { __typename?: 'EbbAndFlowSelfInsight', averageAccuracy: number, averageSpeed: number, speedAccuracyScore: number } } | null } } };

export type DisillusionInsightQueryVariables = Exact<{ [key: string]: never; }>;


export type DisillusionInsightQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gameInsight: { __typename?: 'GameInsight', disillusion?: { __typename?: 'DisillusionInsight', accurateMoveCount: number, gamePlayDate: number, possibleAccurateMoveCount: number, possibleSteadyMoveCount: number, possibleStrategicMoveCount: number, steadyMoveCount: number, strategicMoveCount: number } | null } } };

export type GetAccountStateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountStateQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, hasPremium: boolean, isFreeTrialEligible: boolean } };

export type GetBraintreeClientTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBraintreeClientTokenQuery = { __typename?: 'Query', retrieveBraintreeClientToken: { __typename?: 'BraintreeClientTokenResponse', clientToken: string } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', GetCurrentUser?: { __typename?: 'CurrentUser', id?: number | null, settings?: { __typename?: 'UISettings', showSideNav?: boolean | null, game_sound_on?: boolean | null } | null } | null };

export type GetDemographicInputQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemographicInputQuery = { __typename?: 'Query', validEducation: Array<string>, validGenders: Array<string>, validOccupations: Array<string>, validReferralSources: Array<string> };

export type GetFitTestProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFitTestProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, ageCohort: string, migratedAt?: number | null, originL2?: boolean | null, fitTest: { __typename?: 'FitTest', percentiles?: Array<{ __typename?: 'GamePercentile', gameSlug: string, percentile: number, gamePlay: { __typename?: 'GamePlay', id: number, finishedAt: number } } | null> | null } } };

export type GameProgressFragment = { __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, topStats: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, lastGamePlay?: { __typename?: 'GamePlay', id: number, finishedAt: number, score: number, stat?: number | null } | null };

export type TopScoreFragment = { __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number };

export type GetGameProgressQueryVariables = Exact<{
  games: Array<VersionedGame> | VersionedGame;
  engine: GameEngine;
}>;


export type GetGameProgressQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, gamesProgress: Array<{ __typename?: 'GameProgress', totalPlays: number, rank: string, playsAtRank: number, nextRankAt: number, playerState?: any | null, game: { __typename?: 'Game', slug: string, areaSlug: string }, topScores: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, bestStat?: { __typename?: 'GamePlay', stat?: number | null } | null, topStats: Array<{ __typename?: 'GamePlay', score: number, stat?: number | null, finishedAt: number } | null>, lastGamePlay?: { __typename?: 'GamePlay', id: number, finishedAt: number, score: number, stat?: number | null } | null }> } };

export type GetGamesUnlockedQueryVariables = Exact<{
  availableOnDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetGamesUnlockedQuery = { __typename?: 'Query', gamesUnlocked: Array<{ __typename?: 'GameUnlock', gameSlug: string, startDate: string, endDate: string }> };

export type GetInvitationQueryVariables = Exact<{
  invitationId: Scalars['String'];
}>;


export type GetInvitationQuery = { __typename?: 'Query', getInvitation: { __typename?: 'Invitation', subscriptionNumericId: number, groupManager: { __typename?: 'LimitedUser', email?: string | null, firstName?: string | null, id: number } } };

export type LpiSummaryFragment = { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } };

export type GetLpisAndPercentilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLpisAndPercentilesQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, lpiSummary: { __typename?: 'LpiSummary', overallLpi?: number | null, bestOverallLpi?: number | null, updatedAt: number, lpisByArea: Array<{ __typename?: 'AreaLpi', lpi?: number | null, areaSlug: string }>, ageCohortComparison: { __typename?: 'AgeCohortComparison', ageCohortSlug: string, overallPercentile?: number | null, bestOverallPercentile?: number | null, percentileByArea: Array<{ __typename?: 'AreaPercentile', percentile: number, areaSlug: string }> } } } };

export type GetGameRankLpisQueryVariables = Exact<{
  games: Array<VersionedGame> | VersionedGame;
}>;


export type GetGameRankLpisQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, lpiSummary: { __typename?: 'LpiSummary', updatedAt: number, lpisByGame: Array<{ __typename?: 'GameLpi', lpi?: number | null, game: { __typename?: 'Game', slug: string, areaSlug: string } }> } } };

export type GetMostImprovedGameQueryVariables = Exact<{
  games: Array<VersionedGame> | VersionedGame;
}>;


export type GetMostImprovedGameQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, lpiSummary: { __typename?: 'LpiSummary', updatedAt: number, mostImprovedGames: Array<{ __typename?: 'MostImprovedGames', bucket: string, lpiIncrease: number, game: { __typename?: 'Game', slug: string, areaSlug: string } }> } } };

export type GetConsolidatedGameDatedLpiQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConsolidatedGameDatedLpiQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, lpiSummary: { __typename?: 'LpiSummary', consolidatedGameDatedLpi: Array<{ __typename?: 'ConsolidatedGameDatedLpi', game: { __typename?: 'Game', slug: string, areaSlug: string }, lpis: Array<{ __typename?: 'DatedLpi', finishedAt: number, value: number }> }> } } };

export type GetNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationSettingsQuery = { __typename?: 'Query', reminderSchedule: { __typename?: 'ReminderSchedule', timeSlot: ReminderTimeSlot, days: Array<DayOfWeek>, active: boolean }, me: { __typename?: 'User', id: number, marketingPreferences: { __typename?: 'MarketingPreferences', emailNotifications: { __typename?: 'MarketingPreferencesEmailNotifications', newGamesAndUpdates: boolean, newsletters: boolean, specialOffers: boolean } } } };

export type GetPaymentHistoryLinkDetailsQueryVariables = Exact<{
  headline: Scalars['String'];
}>;


export type GetPaymentHistoryLinkDetailsQuery = { __typename?: 'Query', getPaymentHistoryLinkDetails: Array<{ __typename?: 'PaymentHistoryLinkDetails', agreementCreatedAt?: number | null, provider: PlanProvider, url: string }> };

export type GetPlansQueryVariables = Exact<{
  currency?: InputMaybe<Scalars['String']>;
  skus?: InputMaybe<Array<Sku> | Sku>;
  planSetName?: InputMaybe<Scalars['String']>;
}>;


export type GetPlansQuery = { __typename?: 'Query', retrievePlans: Array<{ __typename?: 'Plan', billingFrequency: BillingFrequency, planType: PlanType, stripePriceId: string, allowPayPal: boolean, isRenewable: boolean, sku: Sku, price: { __typename?: 'PlanPrice', amount: number, currency: any, stripeMinorUnitDivisor: number }, externalIds: { __typename?: 'ExternalIds', apple: string, apple_free_trial: string, google: string, google_free_trial: string, stripe: string, braintree: string } }> };

export type GetPromotionQueryVariables = Exact<{
  promotionCode?: InputMaybe<Scalars['String']>;
  promotionSendDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetPromotionQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, getPromotion: { __typename?: 'Promotion', createdAt: number, id: string, isActive: boolean, percentageOff: number, promotionCode: string, updatedAt: number } } };

export type GetStreaksQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['LocalDate']>;
}>;


export type GetStreaksQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, streakHistory: { __typename?: 'StreakHistory', streaks: Array<{ __typename?: 'Streak', startDate: string, endDate: string, length: number }>, bestStreak?: { __typename?: 'Streak', startDate: string, endDate: string, length: number } | null } } };

export type GetStripePaymentMethodUpdateLinkQueryVariables = Exact<{
  headline: Scalars['String'];
}>;


export type GetStripePaymentMethodUpdateLinkQuery = { __typename?: 'Query', getStripePaymentMethodUpdateLink: string };

export type WorkoutGameFragment = { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } };

export type WorkoutSlotsFragment = { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } };

export type GetTodaysWorkoutQueryVariables = Exact<{
  knownGameSlugs: Array<Scalars['String']> | Scalars['String'];
  mode: Scalars['String'];
}>;


export type GetTodaysWorkoutQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, todaysWorkout: { __typename?: 'Workout', id: string, mode: string, algorithmVersion?: string | null, slots: { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } } } } };

export type GetTodaysAvailableWorkoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTodaysAvailableWorkoutsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, getAvailableWorkouts?: Array<{ __typename?: 'Workout', id: string, mode: string, algorithmVersion?: string | null, slots: { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } } } | null> | null } };

export type LumosSubscriptionFragment = { __typename?: 'LumosSubscription', id: string, currentPeriodStart: number, currentPeriodEnd: number, status: SubscriptionStatus, eligibleForUpgrade: boolean, autoRenews: boolean, planPrice: number, currency: string, provider: PlanProvider, planType: PlanType, sku: Sku, billingFrequency: BillingFrequency, maximumDependents: number, numericId: number, invitationId?: string | null, dependentUsers: Array<{ __typename?: 'LimitedUser', id: number, email?: string | null, firstName?: string | null }> };

export type DependentSubscriptionFragment = { __typename?: 'DependentSubscription', id: string, groupManager?: { __typename?: 'LimitedUser', email?: string | null, firstName?: string | null, id: number } | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, firstName?: string | null, email?: string | null, birthdate?: string | null, education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null, roles: Array<string>, noEnglishFluencyGames: boolean, ageCohort: string, createdAt: number, migratedAt?: number | null, hasPremium: boolean, isFreeTrialEligible: boolean, segmentation?: any | null, subscription?: { __typename?: 'DependentSubscription', id: string, groupManager?: { __typename?: 'LimitedUser', email?: string | null, firstName?: string | null, id: number } | null } | { __typename?: 'LumosSubscription', id: string, currentPeriodStart: number, currentPeriodEnd: number, status: SubscriptionStatus, eligibleForUpgrade: boolean, autoRenews: boolean, planPrice: number, currency: string, provider: PlanProvider, planType: PlanType, sku: Sku, billingFrequency: BillingFrequency, maximumDependents: number, numericId: number, invitationId?: string | null, dependentUsers: Array<{ __typename?: 'LimitedUser', id: number, email?: string | null, firstName?: string | null }> } | null } };

export type GlobalGamesConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalGamesConfigQuery = { __typename?: 'Query', games: Array<{ __typename?: 'GameMetadata', areaSlug: string, englishFluency: boolean, slug: string }> };

export type RemoveDependentUserFromSubscriptionMutationVariables = Exact<{
  input: RemoveDependentUserFromSubscriptionInput;
}>;


export type RemoveDependentUserFromSubscriptionMutation = { __typename?: 'Mutation', removeDependentUserFromSubscription: boolean };

export type RequestAccountDeletionMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestAccountDeletionMutation = { __typename?: 'Mutation', requestAccountDeletion: boolean };

export type SetReminderScheduleActiveMutationVariables = Exact<{
  input: SetReminderScheduleActiveInput;
}>;


export type SetReminderScheduleActiveMutation = { __typename?: 'Mutation', setReminderScheduleActive: { __typename?: 'ReminderSchedule', active: boolean } };

export type UpdateAutoRenewalStatusMutationVariables = Exact<{
  input: UpdateAutoRenewalStatusInput;
}>;


export type UpdateAutoRenewalStatusMutation = { __typename?: 'Mutation', updateAutoRenewalStatus: { __typename?: 'LumosSubscriptionId', id: string } };

export type UpdateBirthdateMutationVariables = Exact<{
  input: UpdateBirthdateInput;
}>;


export type UpdateBirthdateMutation = { __typename?: 'Mutation', updateBirthdate: { __typename?: 'User', id: number, birthdate?: string | null } };

export type UpdateDemographicsMutationVariables = Exact<{
  input: DemographicInput;
}>;


export type UpdateDemographicsMutation = { __typename?: 'Mutation', updateDemographics: { __typename?: 'User', education?: string | null, gender?: string | null, occupation?: string | null, referralSource?: string | null } };

export type UpdateLastUsedLanguageMutationVariables = Exact<{
  lastUsedLanguage: Scalars['String'];
}>;


export type UpdateLastUsedLanguageMutation = { __typename?: 'Mutation', updateLastUsedLanguage: boolean };

export type UpdateMarketingPreferencesMutationVariables = Exact<{
  input: UpdateMarketingPreferencesInput;
}>;


export type UpdateMarketingPreferencesMutation = { __typename?: 'Mutation', updateMarketingPreferences: { __typename?: 'User', id: number } };

export type UpdateNoEnglishFluencyGamesMutationVariables = Exact<{
  input: UpdateNoEnglishFluencyGamesInput;
}>;


export type UpdateNoEnglishFluencyGamesMutation = { __typename?: 'Mutation', updateNoEnglishFluencyGames: { __typename?: 'User', noEnglishFluencyGames: boolean } };

export type UpdateReminderScheduleMutationVariables = Exact<{
  input: UpdateReminderScheduleInput;
}>;


export type UpdateReminderScheduleMutation = { __typename?: 'Mutation', updateReminderSchedule: { __typename?: 'ReminderSchedule', timeSlot: ReminderTimeSlot, days: Array<DayOfWeek>, active: boolean } };

export type UpdateUserSegmentationMutationVariables = Exact<{
  segmentation: Scalars['JSONObject'];
}>;


export type UpdateUserSegmentationMutation = { __typename?: 'Mutation', updateUserSegmentation: { __typename?: 'User', id: number, segmentation?: any | null } };

export type UpdateWorkoutGameMutationVariables = Exact<{
  workoutId: Scalars['ID'];
  workoutSlot: Scalars['Int'];
  newGameSlug: Scalars['String'];
}>;


export type UpdateWorkoutGameMutation = { __typename?: 'Mutation', updateWorkoutGame: { __typename?: 'Workout', id: string, slots: { __typename?: 'WorkoutSlots', slot1: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot2: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot3: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot4: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } }, slot5: { __typename?: 'WorkoutGame', lastGamePlay?: { __typename?: 'GamePlay', finishedAt: number, id: number } | null, game: { __typename?: 'Game', slug: string } } } } };

export type ActivateStripeSubscriptionMutationVariables = Exact<{
  input: StripeSessionInput;
}>;


export type ActivateStripeSubscriptionMutation = { __typename?: 'Mutation', activateStripeSubscription: { __typename?: 'LumosSubscriptionId', id: string } };

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  input: StripeCheckoutSessionInput;
}>;


export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCheckoutSession: { __typename?: 'StripeCheckoutSession', url: string } };

export type CreateStripeCustomCheckoutSessionMutationVariables = Exact<{
  input: StripeCustomCheckoutSessionInput;
}>;


export type CreateStripeCustomCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCustomCheckoutSession: { __typename?: 'StripeCustomCheckoutSession', id: string, clientSecret: string } };

export const TopScoreFragmentDoc = gql`
    fragment TopScore on GamePlay {
  score
  stat
  finishedAt
}
    `;
export const GameProgressFragmentDoc = gql`
    fragment GameProgress on GameProgress {
  game {
    slug
    areaSlug
  }
  totalPlays
  topScores {
    ...TopScore
  }
  bestStat {
    stat
  }
  topStats {
    ...TopScore
  }
  lastGamePlay {
    id
    finishedAt
    score
    stat
  }
  rank
  playsAtRank
  nextRankAt
  playerState(engine: $engine)
}
    ${TopScoreFragmentDoc}`;
export const LpiSummaryFragmentDoc = gql`
    fragment LpiSummary on LpiSummary {
  overallLpi
  bestOverallLpi
  lpisByArea {
    lpi
    areaSlug
  }
  ageCohortComparison {
    ageCohortSlug
    overallPercentile
    bestOverallPercentile
    percentileByArea {
      percentile
      areaSlug
    }
  }
  updatedAt
}
    `;
export const WorkoutGameFragmentDoc = gql`
    fragment WorkoutGame on WorkoutGame {
  lastGamePlay {
    finishedAt
    id
  }
  game {
    slug
  }
}
    `;
export const WorkoutSlotsFragmentDoc = gql`
    fragment WorkoutSlots on WorkoutSlots {
  slot1 {
    ...WorkoutGame
  }
  slot2 {
    ...WorkoutGame
  }
  slot3 {
    ...WorkoutGame
  }
  slot4 {
    ...WorkoutGame
  }
  slot5 {
    ...WorkoutGame
  }
}
    ${WorkoutGameFragmentDoc}`;
export const LumosSubscriptionFragmentDoc = gql`
    fragment LumosSubscription on LumosSubscription {
  id
  currentPeriodStart
  currentPeriodEnd
  status
  eligibleForUpgrade
  autoRenews
  planPrice
  currency
  provider
  planType
  sku
  billingFrequency
  maximumDependents
  numericId
  invitationId
  dependentUsers {
    id
    email
    firstName
  }
}
    `;
export const DependentSubscriptionFragmentDoc = gql`
    fragment DependentSubscription on DependentSubscription {
  groupManager {
    email
    firstName
    id
  }
  id
}
    `;
export const AddCocosGamePlayDocument = gql`
    mutation addCocosGamePlay($input: GamePlayInput!) {
  addCocosGamePlay(input: $input) {
    id
  }
}
    `;
export type AddCocosGamePlayMutationFn = Apollo.MutationFunction<AddCocosGamePlayMutation, AddCocosGamePlayMutationVariables>;

/**
 * __useAddCocosGamePlayMutation__
 *
 * To run a mutation, you first call `useAddCocosGamePlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCocosGamePlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCocosGamePlayMutation, { data, loading, error }] = useAddCocosGamePlayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCocosGamePlayMutation(baseOptions?: Apollo.MutationHookOptions<AddCocosGamePlayMutation, AddCocosGamePlayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCocosGamePlayMutation, AddCocosGamePlayMutationVariables>(AddCocosGamePlayDocument, options);
      }
export type AddCocosGamePlayMutationHookResult = ReturnType<typeof useAddCocosGamePlayMutation>;
export type AddCocosGamePlayMutationResult = Apollo.MutationResult<AddCocosGamePlayMutation>;
export type AddCocosGamePlayMutationOptions = Apollo.BaseMutationOptions<AddCocosGamePlayMutation, AddCocosGamePlayMutationVariables>;
export const AddDependentUserToSubscriptionDocument = gql`
    mutation AddDependentUserToSubscription($input: AddDependentUserToSubscriptionInput!) {
  addDependentUserToSubscription(input: $input)
}
    `;
export type AddDependentUserToSubscriptionMutationFn = Apollo.MutationFunction<AddDependentUserToSubscriptionMutation, AddDependentUserToSubscriptionMutationVariables>;

/**
 * __useAddDependentUserToSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddDependentUserToSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDependentUserToSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDependentUserToSubscriptionMutation, { data, loading, error }] = useAddDependentUserToSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDependentUserToSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddDependentUserToSubscriptionMutation, AddDependentUserToSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDependentUserToSubscriptionMutation, AddDependentUserToSubscriptionMutationVariables>(AddDependentUserToSubscriptionDocument, options);
      }
export type AddDependentUserToSubscriptionMutationHookResult = ReturnType<typeof useAddDependentUserToSubscriptionMutation>;
export type AddDependentUserToSubscriptionMutationResult = Apollo.MutationResult<AddDependentUserToSubscriptionMutation>;
export type AddDependentUserToSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddDependentUserToSubscriptionMutation, AddDependentUserToSubscriptionMutationVariables>;
export const AddUnityGamePlayDocument = gql`
    mutation AddUnityGamePlay($input: GamePlayInput!) {
  addUnityGamePlay(input: $input) {
    id
  }
}
    `;
export type AddUnityGamePlayMutationFn = Apollo.MutationFunction<AddUnityGamePlayMutation, AddUnityGamePlayMutationVariables>;

/**
 * __useAddUnityGamePlayMutation__
 *
 * To run a mutation, you first call `useAddUnityGamePlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUnityGamePlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUnityGamePlayMutation, { data, loading, error }] = useAddUnityGamePlayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUnityGamePlayMutation(baseOptions?: Apollo.MutationHookOptions<AddUnityGamePlayMutation, AddUnityGamePlayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUnityGamePlayMutation, AddUnityGamePlayMutationVariables>(AddUnityGamePlayDocument, options);
      }
export type AddUnityGamePlayMutationHookResult = ReturnType<typeof useAddUnityGamePlayMutation>;
export type AddUnityGamePlayMutationResult = Apollo.MutationResult<AddUnityGamePlayMutation>;
export type AddUnityGamePlayMutationOptions = Apollo.BaseMutationOptions<AddUnityGamePlayMutation, AddUnityGamePlayMutationVariables>;
export const CheckDeferredSubscriptionDocument = gql`
    mutation checkDeferredSubscription($input: CheckDeferredSubscriptionInput!) {
  checkDeferredSubscription(input: $input) {
    id
  }
}
    `;
export type CheckDeferredSubscriptionMutationFn = Apollo.MutationFunction<CheckDeferredSubscriptionMutation, CheckDeferredSubscriptionMutationVariables>;

/**
 * __useCheckDeferredSubscriptionMutation__
 *
 * To run a mutation, you first call `useCheckDeferredSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckDeferredSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkDeferredSubscriptionMutation, { data, loading, error }] = useCheckDeferredSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckDeferredSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CheckDeferredSubscriptionMutation, CheckDeferredSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckDeferredSubscriptionMutation, CheckDeferredSubscriptionMutationVariables>(CheckDeferredSubscriptionDocument, options);
      }
export type CheckDeferredSubscriptionMutationHookResult = ReturnType<typeof useCheckDeferredSubscriptionMutation>;
export type CheckDeferredSubscriptionMutationResult = Apollo.MutationResult<CheckDeferredSubscriptionMutation>;
export type CheckDeferredSubscriptionMutationOptions = Apollo.BaseMutationOptions<CheckDeferredSubscriptionMutation, CheckDeferredSubscriptionMutationVariables>;
export const CreateBraintreeSubscriptionDocument = gql`
    mutation CreateBraintreeSubscription($input: BraintreeSubscriptionInput!) {
  createBraintreeSubscription(input: $input) {
    id
  }
}
    `;
export type CreateBraintreeSubscriptionMutationFn = Apollo.MutationFunction<CreateBraintreeSubscriptionMutation, CreateBraintreeSubscriptionMutationVariables>;

/**
 * __useCreateBraintreeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeSubscriptionMutation, { data, loading, error }] = useCreateBraintreeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBraintreeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBraintreeSubscriptionMutation, CreateBraintreeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBraintreeSubscriptionMutation, CreateBraintreeSubscriptionMutationVariables>(CreateBraintreeSubscriptionDocument, options);
      }
export type CreateBraintreeSubscriptionMutationHookResult = ReturnType<typeof useCreateBraintreeSubscriptionMutation>;
export type CreateBraintreeSubscriptionMutationResult = Apollo.MutationResult<CreateBraintreeSubscriptionMutation>;
export type CreateBraintreeSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateBraintreeSubscriptionMutation, CreateBraintreeSubscriptionMutationVariables>;
export const RaindropInsightDocument = gql`
    query RaindropInsight {
  me {
    id
    gameInsight {
      raindrops {
        gamePlayDate
        missedExpressions {
          answer
          category
          difficulty
          expression
          operandLHS
          operandRHS
          operator
        }
      }
    }
  }
}
    `;

/**
 * __useRaindropInsightQuery__
 *
 * To run a query within a React component, call `useRaindropInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useRaindropInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaindropInsightQuery({
 *   variables: {
 *   },
 * });
 */
export function useRaindropInsightQuery(baseOptions?: Apollo.QueryHookOptions<RaindropInsightQuery, RaindropInsightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RaindropInsightQuery, RaindropInsightQueryVariables>(RaindropInsightDocument, options);
      }
export function useRaindropInsightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RaindropInsightQuery, RaindropInsightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RaindropInsightQuery, RaindropInsightQueryVariables>(RaindropInsightDocument, options);
        }
export type RaindropInsightQueryHookResult = ReturnType<typeof useRaindropInsightQuery>;
export type RaindropInsightLazyQueryHookResult = ReturnType<typeof useRaindropInsightLazyQuery>;
export type RaindropInsightQueryResult = Apollo.QueryResult<RaindropInsightQuery, RaindropInsightQueryVariables>;
export const ConsolidatedGamePlayCountsDocument = gql`
    query ConsolidatedGamePlayCounts {
  me {
    id
    gameInsight {
      consolidatedGamePlayCounts {
        gamePlayCount
        gameSlug
        isAdditionalGameplayRequired
        requiredGamePlayCount
      }
    }
  }
}
    `;

/**
 * __useConsolidatedGamePlayCountsQuery__
 *
 * To run a query within a React component, call `useConsolidatedGamePlayCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidatedGamePlayCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidatedGamePlayCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsolidatedGamePlayCountsQuery(baseOptions?: Apollo.QueryHookOptions<ConsolidatedGamePlayCountsQuery, ConsolidatedGamePlayCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsolidatedGamePlayCountsQuery, ConsolidatedGamePlayCountsQueryVariables>(ConsolidatedGamePlayCountsDocument, options);
      }
export function useConsolidatedGamePlayCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsolidatedGamePlayCountsQuery, ConsolidatedGamePlayCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsolidatedGamePlayCountsQuery, ConsolidatedGamePlayCountsQueryVariables>(ConsolidatedGamePlayCountsDocument, options);
        }
export type ConsolidatedGamePlayCountsQueryHookResult = ReturnType<typeof useConsolidatedGamePlayCountsQuery>;
export type ConsolidatedGamePlayCountsLazyQueryHookResult = ReturnType<typeof useConsolidatedGamePlayCountsLazyQuery>;
export type ConsolidatedGamePlayCountsQueryResult = Apollo.QueryResult<ConsolidatedGamePlayCountsQuery, ConsolidatedGamePlayCountsQueryVariables>;
export const TrainOfThoughtInsightDocument = gql`
    query TrainOfThoughtInsight {
  me {
    id
    gameInsight {
      trainOfThought {
        averageLevel
        gamePlayDate
        others {
          closeCallPercentage
          closeCalls
          missedCallPercentage
          missedCalls
          plannedEarlyCallPercentage
          plannedEarlyCalls
        }
        planningRank
        planningRankPercentile
        self {
          closeCallPercentage
          closeCalls
          missedCallPercentage
          missedCalls
          plannedEarlyCallPercentage
          plannedEarlyCalls
        }
        userLevel
      }
    }
  }
}
    `;

/**
 * __useTrainOfThoughtInsightQuery__
 *
 * To run a query within a React component, call `useTrainOfThoughtInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainOfThoughtInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainOfThoughtInsightQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainOfThoughtInsightQuery(baseOptions?: Apollo.QueryHookOptions<TrainOfThoughtInsightQuery, TrainOfThoughtInsightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrainOfThoughtInsightQuery, TrainOfThoughtInsightQueryVariables>(TrainOfThoughtInsightDocument, options);
      }
export function useTrainOfThoughtInsightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainOfThoughtInsightQuery, TrainOfThoughtInsightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrainOfThoughtInsightQuery, TrainOfThoughtInsightQueryVariables>(TrainOfThoughtInsightDocument, options);
        }
export type TrainOfThoughtInsightQueryHookResult = ReturnType<typeof useTrainOfThoughtInsightQuery>;
export type TrainOfThoughtInsightLazyQueryHookResult = ReturnType<typeof useTrainOfThoughtInsightLazyQuery>;
export type TrainOfThoughtInsightQueryResult = Apollo.QueryResult<TrainOfThoughtInsightQuery, TrainOfThoughtInsightQueryVariables>;
export const LostInMigrationInsightDocument = gql`
    query LostInMigrationInsight {
  me {
    id
    gameInsight {
      lostInMigration {
        filteringStyle
        gamePlayDate
        others {
          matchingFlocks
          nonMatchingFlocks
          slowDownPercentage
        }
        self {
          matchingFlocks
          nonMatchingFlocks
          slowDownPercentage
        }
      }
    }
  }
}
    `;

/**
 * __useLostInMigrationInsightQuery__
 *
 * To run a query within a React component, call `useLostInMigrationInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useLostInMigrationInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLostInMigrationInsightQuery({
 *   variables: {
 *   },
 * });
 */
export function useLostInMigrationInsightQuery(baseOptions?: Apollo.QueryHookOptions<LostInMigrationInsightQuery, LostInMigrationInsightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LostInMigrationInsightQuery, LostInMigrationInsightQueryVariables>(LostInMigrationInsightDocument, options);
      }
export function useLostInMigrationInsightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LostInMigrationInsightQuery, LostInMigrationInsightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LostInMigrationInsightQuery, LostInMigrationInsightQueryVariables>(LostInMigrationInsightDocument, options);
        }
export type LostInMigrationInsightQueryHookResult = ReturnType<typeof useLostInMigrationInsightQuery>;
export type LostInMigrationInsightLazyQueryHookResult = ReturnType<typeof useLostInMigrationInsightLazyQuery>;
export type LostInMigrationInsightQueryResult = Apollo.QueryResult<LostInMigrationInsightQuery, LostInMigrationInsightQueryVariables>;
export const EbbAndFlowInsightsDocument = gql`
    query EbbAndFlowInsights {
  me {
    id
    gameInsight {
      ebbAndFlow {
        gamePlayDate
        others {
          speedAccuracyScore
        }
        self {
          averageAccuracy
          averageSpeed
          speedAccuracyScore
        }
      }
    }
  }
}
    `;

/**
 * __useEbbAndFlowInsightsQuery__
 *
 * To run a query within a React component, call `useEbbAndFlowInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEbbAndFlowInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEbbAndFlowInsightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEbbAndFlowInsightsQuery(baseOptions?: Apollo.QueryHookOptions<EbbAndFlowInsightsQuery, EbbAndFlowInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EbbAndFlowInsightsQuery, EbbAndFlowInsightsQueryVariables>(EbbAndFlowInsightsDocument, options);
      }
export function useEbbAndFlowInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbbAndFlowInsightsQuery, EbbAndFlowInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EbbAndFlowInsightsQuery, EbbAndFlowInsightsQueryVariables>(EbbAndFlowInsightsDocument, options);
        }
export type EbbAndFlowInsightsQueryHookResult = ReturnType<typeof useEbbAndFlowInsightsQuery>;
export type EbbAndFlowInsightsLazyQueryHookResult = ReturnType<typeof useEbbAndFlowInsightsLazyQuery>;
export type EbbAndFlowInsightsQueryResult = Apollo.QueryResult<EbbAndFlowInsightsQuery, EbbAndFlowInsightsQueryVariables>;
export const DisillusionInsightDocument = gql`
    query DisillusionInsight {
  me {
    id
    gameInsight {
      disillusion {
        accurateMoveCount
        gamePlayDate
        possibleAccurateMoveCount
        possibleSteadyMoveCount
        possibleStrategicMoveCount
        steadyMoveCount
        strategicMoveCount
      }
    }
  }
}
    `;

/**
 * __useDisillusionInsightQuery__
 *
 * To run a query within a React component, call `useDisillusionInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisillusionInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisillusionInsightQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisillusionInsightQuery(baseOptions?: Apollo.QueryHookOptions<DisillusionInsightQuery, DisillusionInsightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisillusionInsightQuery, DisillusionInsightQueryVariables>(DisillusionInsightDocument, options);
      }
export function useDisillusionInsightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisillusionInsightQuery, DisillusionInsightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisillusionInsightQuery, DisillusionInsightQueryVariables>(DisillusionInsightDocument, options);
        }
export type DisillusionInsightQueryHookResult = ReturnType<typeof useDisillusionInsightQuery>;
export type DisillusionInsightLazyQueryHookResult = ReturnType<typeof useDisillusionInsightLazyQuery>;
export type DisillusionInsightQueryResult = Apollo.QueryResult<DisillusionInsightQuery, DisillusionInsightQueryVariables>;
export const GetAccountStateDocument = gql`
    query GetAccountState {
  me {
    id
    hasPremium
    isFreeTrialEligible
  }
}
    `;

/**
 * __useGetAccountStateQuery__
 *
 * To run a query within a React component, call `useGetAccountStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountStateQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(GetAccountStateDocument, options);
      }
export function useGetAccountStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountStateQuery, GetAccountStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountStateQuery, GetAccountStateQueryVariables>(GetAccountStateDocument, options);
        }
export type GetAccountStateQueryHookResult = ReturnType<typeof useGetAccountStateQuery>;
export type GetAccountStateLazyQueryHookResult = ReturnType<typeof useGetAccountStateLazyQuery>;
export type GetAccountStateQueryResult = Apollo.QueryResult<GetAccountStateQuery, GetAccountStateQueryVariables>;
export const GetBraintreeClientTokenDocument = gql`
    query GetBraintreeClientToken {
  retrieveBraintreeClientToken {
    clientToken
  }
}
    `;

/**
 * __useGetBraintreeClientTokenQuery__
 *
 * To run a query within a React component, call `useGetBraintreeClientTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBraintreeClientTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBraintreeClientTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBraintreeClientTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetBraintreeClientTokenQuery, GetBraintreeClientTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBraintreeClientTokenQuery, GetBraintreeClientTokenQueryVariables>(GetBraintreeClientTokenDocument, options);
      }
export function useGetBraintreeClientTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBraintreeClientTokenQuery, GetBraintreeClientTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBraintreeClientTokenQuery, GetBraintreeClientTokenQueryVariables>(GetBraintreeClientTokenDocument, options);
        }
export type GetBraintreeClientTokenQueryHookResult = ReturnType<typeof useGetBraintreeClientTokenQuery>;
export type GetBraintreeClientTokenLazyQueryHookResult = ReturnType<typeof useGetBraintreeClientTokenLazyQuery>;
export type GetBraintreeClientTokenQueryResult = Apollo.QueryResult<GetBraintreeClientTokenQuery, GetBraintreeClientTokenQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  GetCurrentUser @client {
    id
    settings {
      showSideNav
      game_sound_on
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDemographicInputDocument = gql`
    query GetDemographicInput {
  validEducation
  validGenders
  validOccupations
  validReferralSources
}
    `;

/**
 * __useGetDemographicInputQuery__
 *
 * To run a query within a React component, call `useGetDemographicInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemographicInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemographicInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemographicInputQuery(baseOptions?: Apollo.QueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
      }
export function useGetDemographicInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemographicInputQuery, GetDemographicInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemographicInputQuery, GetDemographicInputQueryVariables>(GetDemographicInputDocument, options);
        }
export type GetDemographicInputQueryHookResult = ReturnType<typeof useGetDemographicInputQuery>;
export type GetDemographicInputLazyQueryHookResult = ReturnType<typeof useGetDemographicInputLazyQuery>;
export type GetDemographicInputQueryResult = Apollo.QueryResult<GetDemographicInputQuery, GetDemographicInputQueryVariables>;
export const GetFitTestProgressDocument = gql`
    query GetFitTestProgress {
  me {
    id
    ageCohort
    migratedAt
    originL2
    fitTest {
      percentiles {
        gameSlug
        gamePlay {
          id
          finishedAt
        }
        percentile
      }
    }
  }
}
    `;

/**
 * __useGetFitTestProgressQuery__
 *
 * To run a query within a React component, call `useGetFitTestProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFitTestProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFitTestProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFitTestProgressQuery(baseOptions?: Apollo.QueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
      }
export function useGetFitTestProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>(GetFitTestProgressDocument, options);
        }
export type GetFitTestProgressQueryHookResult = ReturnType<typeof useGetFitTestProgressQuery>;
export type GetFitTestProgressLazyQueryHookResult = ReturnType<typeof useGetFitTestProgressLazyQuery>;
export type GetFitTestProgressQueryResult = Apollo.QueryResult<GetFitTestProgressQuery, GetFitTestProgressQueryVariables>;
export const GetGameProgressDocument = gql`
    query GetGameProgress($games: [VersionedGame!]!, $engine: GameEngine!) {
  me {
    id
    gamesProgress(games: $games) {
      ...GameProgress
    }
  }
}
    ${GameProgressFragmentDoc}`;

/**
 * __useGetGameProgressQuery__
 *
 * To run a query within a React component, call `useGetGameProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameProgressQuery({
 *   variables: {
 *      games: // value for 'games'
 *      engine: // value for 'engine'
 *   },
 * });
 */
export function useGetGameProgressQuery(baseOptions: Apollo.QueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
      }
export function useGetGameProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameProgressQuery, GetGameProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameProgressQuery, GetGameProgressQueryVariables>(GetGameProgressDocument, options);
        }
export type GetGameProgressQueryHookResult = ReturnType<typeof useGetGameProgressQuery>;
export type GetGameProgressLazyQueryHookResult = ReturnType<typeof useGetGameProgressLazyQuery>;
export type GetGameProgressQueryResult = Apollo.QueryResult<GetGameProgressQuery, GetGameProgressQueryVariables>;
export const GetGamesUnlockedDocument = gql`
    query GetGamesUnlocked($availableOnDate: LocalDate) {
  gamesUnlocked(availableOnDate: $availableOnDate) {
    gameSlug
    startDate
    endDate
  }
}
    `;

/**
 * __useGetGamesUnlockedQuery__
 *
 * To run a query within a React component, call `useGetGamesUnlockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesUnlockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesUnlockedQuery({
 *   variables: {
 *      availableOnDate: // value for 'availableOnDate'
 *   },
 * });
 */
export function useGetGamesUnlockedQuery(baseOptions?: Apollo.QueryHookOptions<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>(GetGamesUnlockedDocument, options);
      }
export function useGetGamesUnlockedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>(GetGamesUnlockedDocument, options);
        }
export type GetGamesUnlockedQueryHookResult = ReturnType<typeof useGetGamesUnlockedQuery>;
export type GetGamesUnlockedLazyQueryHookResult = ReturnType<typeof useGetGamesUnlockedLazyQuery>;
export type GetGamesUnlockedQueryResult = Apollo.QueryResult<GetGamesUnlockedQuery, GetGamesUnlockedQueryVariables>;
export const GetInvitationDocument = gql`
    query GetInvitation($invitationId: String!) {
  getInvitation(invitationID: $invitationId) {
    groupManager {
      email
      firstName
      id
    }
    subscriptionNumericId
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const GetLpisAndPercentilesDocument = gql`
    query GetLpisAndPercentiles {
  me {
    id
    lpiSummary {
      ...LpiSummary
    }
  }
}
    ${LpiSummaryFragmentDoc}`;

/**
 * __useGetLpisAndPercentilesQuery__
 *
 * To run a query within a React component, call `useGetLpisAndPercentilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLpisAndPercentilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLpisAndPercentilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLpisAndPercentilesQuery(baseOptions?: Apollo.QueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
      }
export function useGetLpisAndPercentilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>(GetLpisAndPercentilesDocument, options);
        }
export type GetLpisAndPercentilesQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesQuery>;
export type GetLpisAndPercentilesLazyQueryHookResult = ReturnType<typeof useGetLpisAndPercentilesLazyQuery>;
export type GetLpisAndPercentilesQueryResult = Apollo.QueryResult<GetLpisAndPercentilesQuery, GetLpisAndPercentilesQueryVariables>;
export const GetGameRankLpisDocument = gql`
    query GetGameRankLpis($games: [VersionedGame!]!) {
  me {
    id
    lpiSummary {
      lpisByGame(games: $games) {
        game {
          slug
          areaSlug
        }
        lpi
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useGetGameRankLpisQuery__
 *
 * To run a query within a React component, call `useGetGameRankLpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameRankLpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameRankLpisQuery({
 *   variables: {
 *      games: // value for 'games'
 *   },
 * });
 */
export function useGetGameRankLpisQuery(baseOptions: Apollo.QueryHookOptions<GetGameRankLpisQuery, GetGameRankLpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameRankLpisQuery, GetGameRankLpisQueryVariables>(GetGameRankLpisDocument, options);
      }
export function useGetGameRankLpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameRankLpisQuery, GetGameRankLpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameRankLpisQuery, GetGameRankLpisQueryVariables>(GetGameRankLpisDocument, options);
        }
export type GetGameRankLpisQueryHookResult = ReturnType<typeof useGetGameRankLpisQuery>;
export type GetGameRankLpisLazyQueryHookResult = ReturnType<typeof useGetGameRankLpisLazyQuery>;
export type GetGameRankLpisQueryResult = Apollo.QueryResult<GetGameRankLpisQuery, GetGameRankLpisQueryVariables>;
export const GetMostImprovedGameDocument = gql`
    query GetMostImprovedGame($games: [VersionedGame!]!) {
  me {
    id
    lpiSummary {
      mostImprovedGames(games: $games) {
        game {
          slug
          areaSlug
        }
        bucket
        lpiIncrease
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMostImprovedGameQuery__
 *
 * To run a query within a React component, call `useGetMostImprovedGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostImprovedGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostImprovedGameQuery({
 *   variables: {
 *      games: // value for 'games'
 *   },
 * });
 */
export function useGetMostImprovedGameQuery(baseOptions: Apollo.QueryHookOptions<GetMostImprovedGameQuery, GetMostImprovedGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostImprovedGameQuery, GetMostImprovedGameQueryVariables>(GetMostImprovedGameDocument, options);
      }
export function useGetMostImprovedGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostImprovedGameQuery, GetMostImprovedGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostImprovedGameQuery, GetMostImprovedGameQueryVariables>(GetMostImprovedGameDocument, options);
        }
export type GetMostImprovedGameQueryHookResult = ReturnType<typeof useGetMostImprovedGameQuery>;
export type GetMostImprovedGameLazyQueryHookResult = ReturnType<typeof useGetMostImprovedGameLazyQuery>;
export type GetMostImprovedGameQueryResult = Apollo.QueryResult<GetMostImprovedGameQuery, GetMostImprovedGameQueryVariables>;
export const GetConsolidatedGameDatedLpiDocument = gql`
    query GetConsolidatedGameDatedLpi {
  me {
    id
    lpiSummary {
      consolidatedGameDatedLpi {
        game {
          slug
          areaSlug
        }
        lpis {
          finishedAt
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetConsolidatedGameDatedLpiQuery__
 *
 * To run a query within a React component, call `useGetConsolidatedGameDatedLpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidatedGameDatedLpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidatedGameDatedLpiQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConsolidatedGameDatedLpiQuery(baseOptions?: Apollo.QueryHookOptions<GetConsolidatedGameDatedLpiQuery, GetConsolidatedGameDatedLpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsolidatedGameDatedLpiQuery, GetConsolidatedGameDatedLpiQueryVariables>(GetConsolidatedGameDatedLpiDocument, options);
      }
export function useGetConsolidatedGameDatedLpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsolidatedGameDatedLpiQuery, GetConsolidatedGameDatedLpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsolidatedGameDatedLpiQuery, GetConsolidatedGameDatedLpiQueryVariables>(GetConsolidatedGameDatedLpiDocument, options);
        }
export type GetConsolidatedGameDatedLpiQueryHookResult = ReturnType<typeof useGetConsolidatedGameDatedLpiQuery>;
export type GetConsolidatedGameDatedLpiLazyQueryHookResult = ReturnType<typeof useGetConsolidatedGameDatedLpiLazyQuery>;
export type GetConsolidatedGameDatedLpiQueryResult = Apollo.QueryResult<GetConsolidatedGameDatedLpiQuery, GetConsolidatedGameDatedLpiQueryVariables>;
export const GetNotificationSettingsDocument = gql`
    query GetNotificationSettings {
  reminderSchedule {
    timeSlot
    days
    active
  }
  me {
    id
    marketingPreferences {
      emailNotifications {
        newGamesAndUpdates
        newsletters
        specialOffers
      }
    }
  }
}
    `;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
      }
export function useGetNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export type GetNotificationSettingsQueryHookResult = ReturnType<typeof useGetNotificationSettingsQuery>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingsLazyQuery>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>;
export const GetPaymentHistoryLinkDetailsDocument = gql`
    query GetPaymentHistoryLinkDetails($headline: String!) {
  getPaymentHistoryLinkDetails(headline: $headline) {
    agreementCreatedAt
    provider
    url
  }
}
    `;

/**
 * __useGetPaymentHistoryLinkDetailsQuery__
 *
 * To run a query within a React component, call `useGetPaymentHistoryLinkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentHistoryLinkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentHistoryLinkDetailsQuery({
 *   variables: {
 *      headline: // value for 'headline'
 *   },
 * });
 */
export function useGetPaymentHistoryLinkDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentHistoryLinkDetailsQuery, GetPaymentHistoryLinkDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentHistoryLinkDetailsQuery, GetPaymentHistoryLinkDetailsQueryVariables>(GetPaymentHistoryLinkDetailsDocument, options);
      }
export function useGetPaymentHistoryLinkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentHistoryLinkDetailsQuery, GetPaymentHistoryLinkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentHistoryLinkDetailsQuery, GetPaymentHistoryLinkDetailsQueryVariables>(GetPaymentHistoryLinkDetailsDocument, options);
        }
export type GetPaymentHistoryLinkDetailsQueryHookResult = ReturnType<typeof useGetPaymentHistoryLinkDetailsQuery>;
export type GetPaymentHistoryLinkDetailsLazyQueryHookResult = ReturnType<typeof useGetPaymentHistoryLinkDetailsLazyQuery>;
export type GetPaymentHistoryLinkDetailsQueryResult = Apollo.QueryResult<GetPaymentHistoryLinkDetailsQuery, GetPaymentHistoryLinkDetailsQueryVariables>;
export const GetPlansDocument = gql`
    query GetPlans($currency: String, $skus: [SKU!], $planSetName: String) {
  retrievePlans(currency: $currency, skus: $skus, planSetName: $planSetName) {
    billingFrequency
    planType
    stripePriceId
    allowPayPal
    isRenewable
    price {
      amount
      currency
      stripeMinorUnitDivisor
    }
    externalIds {
      apple
      apple_free_trial
      google
      google_free_trial
      stripe
      braintree
    }
    sku
  }
}
    `;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      skus: // value for 'skus'
 *      planSetName: // value for 'planSetName'
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
      }
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
        }
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const GetPromotionDocument = gql`
    query GetPromotion($promotionCode: String, $promotionSendDate: LocalDate) {
  me {
    id
    getPromotion(
      promotionCode: $promotionCode
      promotionSendDate: $promotionSendDate
    ) {
      createdAt
      id
      isActive
      percentageOff
      promotionCode
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *      promotionCode: // value for 'promotionCode'
 *      promotionSendDate: // value for 'promotionSendDate'
 *   },
 * });
 */
export function useGetPromotionQuery(baseOptions?: Apollo.QueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
      }
export function useGetPromotionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionQuery, GetPromotionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPromotionQuery, GetPromotionQueryVariables>(GetPromotionDocument, options);
        }
export type GetPromotionQueryHookResult = ReturnType<typeof useGetPromotionQuery>;
export type GetPromotionLazyQueryHookResult = ReturnType<typeof useGetPromotionLazyQuery>;
export type GetPromotionQueryResult = Apollo.QueryResult<GetPromotionQuery, GetPromotionQueryVariables>;
export const GetStreaksDocument = gql`
    query GetStreaks($startDate: LocalDate) {
  me {
    id
    streakHistory {
      streaks(startDate: $startDate) {
        startDate
        endDate
        length
      }
      bestStreak {
        startDate
        endDate
        length
      }
    }
  }
}
    `;

/**
 * __useGetStreaksQuery__
 *
 * To run a query within a React component, call `useGetStreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreaksQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetStreaksQuery(baseOptions?: Apollo.QueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
      }
export function useGetStreaksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreaksQuery, GetStreaksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreaksQuery, GetStreaksQueryVariables>(GetStreaksDocument, options);
        }
export type GetStreaksQueryHookResult = ReturnType<typeof useGetStreaksQuery>;
export type GetStreaksLazyQueryHookResult = ReturnType<typeof useGetStreaksLazyQuery>;
export type GetStreaksQueryResult = Apollo.QueryResult<GetStreaksQuery, GetStreaksQueryVariables>;
export const GetStripePaymentMethodUpdateLinkDocument = gql`
    query GetStripePaymentMethodUpdateLink($headline: String!) {
  getStripePaymentMethodUpdateLink(headline: $headline)
}
    `;

/**
 * __useGetStripePaymentMethodUpdateLinkQuery__
 *
 * To run a query within a React component, call `useGetStripePaymentMethodUpdateLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripePaymentMethodUpdateLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripePaymentMethodUpdateLinkQuery({
 *   variables: {
 *      headline: // value for 'headline'
 *   },
 * });
 */
export function useGetStripePaymentMethodUpdateLinkQuery(baseOptions: Apollo.QueryHookOptions<GetStripePaymentMethodUpdateLinkQuery, GetStripePaymentMethodUpdateLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripePaymentMethodUpdateLinkQuery, GetStripePaymentMethodUpdateLinkQueryVariables>(GetStripePaymentMethodUpdateLinkDocument, options);
      }
export function useGetStripePaymentMethodUpdateLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripePaymentMethodUpdateLinkQuery, GetStripePaymentMethodUpdateLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripePaymentMethodUpdateLinkQuery, GetStripePaymentMethodUpdateLinkQueryVariables>(GetStripePaymentMethodUpdateLinkDocument, options);
        }
export type GetStripePaymentMethodUpdateLinkQueryHookResult = ReturnType<typeof useGetStripePaymentMethodUpdateLinkQuery>;
export type GetStripePaymentMethodUpdateLinkLazyQueryHookResult = ReturnType<typeof useGetStripePaymentMethodUpdateLinkLazyQuery>;
export type GetStripePaymentMethodUpdateLinkQueryResult = Apollo.QueryResult<GetStripePaymentMethodUpdateLinkQuery, GetStripePaymentMethodUpdateLinkQueryVariables>;
export const GetTodaysWorkoutDocument = gql`
    query GetTodaysWorkout($knownGameSlugs: [String!]!, $mode: String!) {
  me {
    id
    todaysWorkout(knownGameSlugs: $knownGameSlugs, mode: $mode) {
      id
      mode
      algorithmVersion
      slots {
        ...WorkoutSlots
      }
    }
  }
}
    ${WorkoutSlotsFragmentDoc}`;

/**
 * __useGetTodaysWorkoutQuery__
 *
 * To run a query within a React component, call `useGetTodaysWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodaysWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodaysWorkoutQuery({
 *   variables: {
 *      knownGameSlugs: // value for 'knownGameSlugs'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useGetTodaysWorkoutQuery(baseOptions: Apollo.QueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
      }
export function useGetTodaysWorkoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>(GetTodaysWorkoutDocument, options);
        }
export type GetTodaysWorkoutQueryHookResult = ReturnType<typeof useGetTodaysWorkoutQuery>;
export type GetTodaysWorkoutLazyQueryHookResult = ReturnType<typeof useGetTodaysWorkoutLazyQuery>;
export type GetTodaysWorkoutQueryResult = Apollo.QueryResult<GetTodaysWorkoutQuery, GetTodaysWorkoutQueryVariables>;
export const GetTodaysAvailableWorkoutsDocument = gql`
    query GetTodaysAvailableWorkouts {
  me {
    id
    getAvailableWorkouts {
      id
      mode
      algorithmVersion
      slots {
        ...WorkoutSlots
      }
    }
  }
}
    ${WorkoutSlotsFragmentDoc}`;

/**
 * __useGetTodaysAvailableWorkoutsQuery__
 *
 * To run a query within a React component, call `useGetTodaysAvailableWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodaysAvailableWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodaysAvailableWorkoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTodaysAvailableWorkoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetTodaysAvailableWorkoutsQuery, GetTodaysAvailableWorkoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTodaysAvailableWorkoutsQuery, GetTodaysAvailableWorkoutsQueryVariables>(GetTodaysAvailableWorkoutsDocument, options);
      }
export function useGetTodaysAvailableWorkoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTodaysAvailableWorkoutsQuery, GetTodaysAvailableWorkoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTodaysAvailableWorkoutsQuery, GetTodaysAvailableWorkoutsQueryVariables>(GetTodaysAvailableWorkoutsDocument, options);
        }
export type GetTodaysAvailableWorkoutsQueryHookResult = ReturnType<typeof useGetTodaysAvailableWorkoutsQuery>;
export type GetTodaysAvailableWorkoutsLazyQueryHookResult = ReturnType<typeof useGetTodaysAvailableWorkoutsLazyQuery>;
export type GetTodaysAvailableWorkoutsQueryResult = Apollo.QueryResult<GetTodaysAvailableWorkoutsQuery, GetTodaysAvailableWorkoutsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  me {
    id
    firstName
    email
    birthdate
    education
    gender
    occupation
    referralSource
    roles
    noEnglishFluencyGames
    ageCohort
    createdAt
    migratedAt
    hasPremium
    isFreeTrialEligible
    segmentation
    subscription {
      ...LumosSubscription
      ...DependentSubscription
    }
  }
}
    ${LumosSubscriptionFragmentDoc}
${DependentSubscriptionFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GlobalGamesConfigDocument = gql`
    query GlobalGamesConfig {
  games {
    areaSlug
    englishFluency
    slug
  }
}
    `;

/**
 * __useGlobalGamesConfigQuery__
 *
 * To run a query within a React component, call `useGlobalGamesConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalGamesConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalGamesConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalGamesConfigQuery(baseOptions?: Apollo.QueryHookOptions<GlobalGamesConfigQuery, GlobalGamesConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalGamesConfigQuery, GlobalGamesConfigQueryVariables>(GlobalGamesConfigDocument, options);
      }
export function useGlobalGamesConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalGamesConfigQuery, GlobalGamesConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalGamesConfigQuery, GlobalGamesConfigQueryVariables>(GlobalGamesConfigDocument, options);
        }
export type GlobalGamesConfigQueryHookResult = ReturnType<typeof useGlobalGamesConfigQuery>;
export type GlobalGamesConfigLazyQueryHookResult = ReturnType<typeof useGlobalGamesConfigLazyQuery>;
export type GlobalGamesConfigQueryResult = Apollo.QueryResult<GlobalGamesConfigQuery, GlobalGamesConfigQueryVariables>;
export const RemoveDependentUserFromSubscriptionDocument = gql`
    mutation RemoveDependentUserFromSubscription($input: RemoveDependentUserFromSubscriptionInput!) {
  removeDependentUserFromSubscription(input: $input)
}
    `;
export type RemoveDependentUserFromSubscriptionMutationFn = Apollo.MutationFunction<RemoveDependentUserFromSubscriptionMutation, RemoveDependentUserFromSubscriptionMutationVariables>;

/**
 * __useRemoveDependentUserFromSubscriptionMutation__
 *
 * To run a mutation, you first call `useRemoveDependentUserFromSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDependentUserFromSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDependentUserFromSubscriptionMutation, { data, loading, error }] = useRemoveDependentUserFromSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDependentUserFromSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDependentUserFromSubscriptionMutation, RemoveDependentUserFromSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDependentUserFromSubscriptionMutation, RemoveDependentUserFromSubscriptionMutationVariables>(RemoveDependentUserFromSubscriptionDocument, options);
      }
export type RemoveDependentUserFromSubscriptionMutationHookResult = ReturnType<typeof useRemoveDependentUserFromSubscriptionMutation>;
export type RemoveDependentUserFromSubscriptionMutationResult = Apollo.MutationResult<RemoveDependentUserFromSubscriptionMutation>;
export type RemoveDependentUserFromSubscriptionMutationOptions = Apollo.BaseMutationOptions<RemoveDependentUserFromSubscriptionMutation, RemoveDependentUserFromSubscriptionMutationVariables>;
export const RequestAccountDeletionDocument = gql`
    mutation RequestAccountDeletion {
  requestAccountDeletion
}
    `;
export type RequestAccountDeletionMutationFn = Apollo.MutationFunction<RequestAccountDeletionMutation, RequestAccountDeletionMutationVariables>;

/**
 * __useRequestAccountDeletionMutation__
 *
 * To run a mutation, you first call `useRequestAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountDeletionMutation, { data, loading, error }] = useRequestAccountDeletionMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestAccountDeletionMutation(baseOptions?: Apollo.MutationHookOptions<RequestAccountDeletionMutation, RequestAccountDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAccountDeletionMutation, RequestAccountDeletionMutationVariables>(RequestAccountDeletionDocument, options);
      }
export type RequestAccountDeletionMutationHookResult = ReturnType<typeof useRequestAccountDeletionMutation>;
export type RequestAccountDeletionMutationResult = Apollo.MutationResult<RequestAccountDeletionMutation>;
export type RequestAccountDeletionMutationOptions = Apollo.BaseMutationOptions<RequestAccountDeletionMutation, RequestAccountDeletionMutationVariables>;
export const SetReminderScheduleActiveDocument = gql`
    mutation SetReminderScheduleActive($input: SetReminderScheduleActiveInput!) {
  setReminderScheduleActive(input: $input) {
    active
  }
}
    `;
export type SetReminderScheduleActiveMutationFn = Apollo.MutationFunction<SetReminderScheduleActiveMutation, SetReminderScheduleActiveMutationVariables>;

/**
 * __useSetReminderScheduleActiveMutation__
 *
 * To run a mutation, you first call `useSetReminderScheduleActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReminderScheduleActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReminderScheduleActiveMutation, { data, loading, error }] = useSetReminderScheduleActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReminderScheduleActiveMutation(baseOptions?: Apollo.MutationHookOptions<SetReminderScheduleActiveMutation, SetReminderScheduleActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReminderScheduleActiveMutation, SetReminderScheduleActiveMutationVariables>(SetReminderScheduleActiveDocument, options);
      }
export type SetReminderScheduleActiveMutationHookResult = ReturnType<typeof useSetReminderScheduleActiveMutation>;
export type SetReminderScheduleActiveMutationResult = Apollo.MutationResult<SetReminderScheduleActiveMutation>;
export type SetReminderScheduleActiveMutationOptions = Apollo.BaseMutationOptions<SetReminderScheduleActiveMutation, SetReminderScheduleActiveMutationVariables>;
export const UpdateAutoRenewalStatusDocument = gql`
    mutation UpdateAutoRenewalStatus($input: UpdateAutoRenewalStatusInput!) {
  updateAutoRenewalStatus(input: $input) {
    id
  }
}
    `;
export type UpdateAutoRenewalStatusMutationFn = Apollo.MutationFunction<UpdateAutoRenewalStatusMutation, UpdateAutoRenewalStatusMutationVariables>;

/**
 * __useUpdateAutoRenewalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAutoRenewalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoRenewalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoRenewalStatusMutation, { data, loading, error }] = useUpdateAutoRenewalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutoRenewalStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoRenewalStatusMutation, UpdateAutoRenewalStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoRenewalStatusMutation, UpdateAutoRenewalStatusMutationVariables>(UpdateAutoRenewalStatusDocument, options);
      }
export type UpdateAutoRenewalStatusMutationHookResult = ReturnType<typeof useUpdateAutoRenewalStatusMutation>;
export type UpdateAutoRenewalStatusMutationResult = Apollo.MutationResult<UpdateAutoRenewalStatusMutation>;
export type UpdateAutoRenewalStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAutoRenewalStatusMutation, UpdateAutoRenewalStatusMutationVariables>;
export const UpdateBirthdateDocument = gql`
    mutation UpdateBirthdate($input: UpdateBirthdateInput!) {
  updateBirthdate(input: $input) {
    id
    birthdate
  }
}
    `;
export type UpdateBirthdateMutationFn = Apollo.MutationFunction<UpdateBirthdateMutation, UpdateBirthdateMutationVariables>;

/**
 * __useUpdateBirthdateMutation__
 *
 * To run a mutation, you first call `useUpdateBirthdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBirthdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBirthdateMutation, { data, loading, error }] = useUpdateBirthdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBirthdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBirthdateMutation, UpdateBirthdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBirthdateMutation, UpdateBirthdateMutationVariables>(UpdateBirthdateDocument, options);
      }
export type UpdateBirthdateMutationHookResult = ReturnType<typeof useUpdateBirthdateMutation>;
export type UpdateBirthdateMutationResult = Apollo.MutationResult<UpdateBirthdateMutation>;
export type UpdateBirthdateMutationOptions = Apollo.BaseMutationOptions<UpdateBirthdateMutation, UpdateBirthdateMutationVariables>;
export const UpdateDemographicsDocument = gql`
    mutation UpdateDemographics($input: DemographicInput!) {
  updateDemographics(input: $input) {
    education
    gender
    occupation
    referralSource
  }
}
    `;
export type UpdateDemographicsMutationFn = Apollo.MutationFunction<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;

/**
 * __useUpdateDemographicsMutation__
 *
 * To run a mutation, you first call `useUpdateDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDemographicsMutation, { data, loading, error }] = useUpdateDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDemographicsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>(UpdateDemographicsDocument, options);
      }
export type UpdateDemographicsMutationHookResult = ReturnType<typeof useUpdateDemographicsMutation>;
export type UpdateDemographicsMutationResult = Apollo.MutationResult<UpdateDemographicsMutation>;
export type UpdateDemographicsMutationOptions = Apollo.BaseMutationOptions<UpdateDemographicsMutation, UpdateDemographicsMutationVariables>;
export const UpdateLastUsedLanguageDocument = gql`
    mutation UpdateLastUsedLanguage($lastUsedLanguage: String!) {
  updateLastUsedLanguage(lastUsedLanguage: $lastUsedLanguage)
}
    `;
export type UpdateLastUsedLanguageMutationFn = Apollo.MutationFunction<UpdateLastUsedLanguageMutation, UpdateLastUsedLanguageMutationVariables>;

/**
 * __useUpdateLastUsedLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateLastUsedLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastUsedLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastUsedLanguageMutation, { data, loading, error }] = useUpdateLastUsedLanguageMutation({
 *   variables: {
 *      lastUsedLanguage: // value for 'lastUsedLanguage'
 *   },
 * });
 */
export function useUpdateLastUsedLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastUsedLanguageMutation, UpdateLastUsedLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLastUsedLanguageMutation, UpdateLastUsedLanguageMutationVariables>(UpdateLastUsedLanguageDocument, options);
      }
export type UpdateLastUsedLanguageMutationHookResult = ReturnType<typeof useUpdateLastUsedLanguageMutation>;
export type UpdateLastUsedLanguageMutationResult = Apollo.MutationResult<UpdateLastUsedLanguageMutation>;
export type UpdateLastUsedLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateLastUsedLanguageMutation, UpdateLastUsedLanguageMutationVariables>;
export const UpdateMarketingPreferencesDocument = gql`
    mutation UpdateMarketingPreferences($input: UpdateMarketingPreferencesInput!) {
  updateMarketingPreferences(input: $input) {
    id
  }
}
    `;
export type UpdateMarketingPreferencesMutationFn = Apollo.MutationFunction<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>;

/**
 * __useUpdateMarketingPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateMarketingPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketingPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketingPreferencesMutation, { data, loading, error }] = useUpdateMarketingPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMarketingPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>(UpdateMarketingPreferencesDocument, options);
      }
export type UpdateMarketingPreferencesMutationHookResult = ReturnType<typeof useUpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationResult = Apollo.MutationResult<UpdateMarketingPreferencesMutation>;
export type UpdateMarketingPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateMarketingPreferencesMutation, UpdateMarketingPreferencesMutationVariables>;
export const UpdateNoEnglishFluencyGamesDocument = gql`
    mutation UpdateNoEnglishFluencyGames($input: UpdateNoEnglishFluencyGamesInput!) {
  updateNoEnglishFluencyGames(input: $input) {
    noEnglishFluencyGames
  }
}
    `;
export type UpdateNoEnglishFluencyGamesMutationFn = Apollo.MutationFunction<UpdateNoEnglishFluencyGamesMutation, UpdateNoEnglishFluencyGamesMutationVariables>;

/**
 * __useUpdateNoEnglishFluencyGamesMutation__
 *
 * To run a mutation, you first call `useUpdateNoEnglishFluencyGamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoEnglishFluencyGamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoEnglishFluencyGamesMutation, { data, loading, error }] = useUpdateNoEnglishFluencyGamesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoEnglishFluencyGamesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoEnglishFluencyGamesMutation, UpdateNoEnglishFluencyGamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoEnglishFluencyGamesMutation, UpdateNoEnglishFluencyGamesMutationVariables>(UpdateNoEnglishFluencyGamesDocument, options);
      }
export type UpdateNoEnglishFluencyGamesMutationHookResult = ReturnType<typeof useUpdateNoEnglishFluencyGamesMutation>;
export type UpdateNoEnglishFluencyGamesMutationResult = Apollo.MutationResult<UpdateNoEnglishFluencyGamesMutation>;
export type UpdateNoEnglishFluencyGamesMutationOptions = Apollo.BaseMutationOptions<UpdateNoEnglishFluencyGamesMutation, UpdateNoEnglishFluencyGamesMutationVariables>;
export const UpdateReminderScheduleDocument = gql`
    mutation UpdateReminderSchedule($input: UpdateReminderScheduleInput!) {
  updateReminderSchedule(input: $input) {
    timeSlot
    days
    active
  }
}
    `;
export type UpdateReminderScheduleMutationFn = Apollo.MutationFunction<UpdateReminderScheduleMutation, UpdateReminderScheduleMutationVariables>;

/**
 * __useUpdateReminderScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateReminderScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderScheduleMutation, { data, loading, error }] = useUpdateReminderScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReminderScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderScheduleMutation, UpdateReminderScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReminderScheduleMutation, UpdateReminderScheduleMutationVariables>(UpdateReminderScheduleDocument, options);
      }
export type UpdateReminderScheduleMutationHookResult = ReturnType<typeof useUpdateReminderScheduleMutation>;
export type UpdateReminderScheduleMutationResult = Apollo.MutationResult<UpdateReminderScheduleMutation>;
export type UpdateReminderScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateReminderScheduleMutation, UpdateReminderScheduleMutationVariables>;
export const UpdateUserSegmentationDocument = gql`
    mutation UpdateUserSegmentation($segmentation: JSONObject!) {
  updateUserSegmentation(segmentation: $segmentation) {
    id
    segmentation
  }
}
    `;
export type UpdateUserSegmentationMutationFn = Apollo.MutationFunction<UpdateUserSegmentationMutation, UpdateUserSegmentationMutationVariables>;

/**
 * __useUpdateUserSegmentationMutation__
 *
 * To run a mutation, you first call `useUpdateUserSegmentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSegmentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSegmentationMutation, { data, loading, error }] = useUpdateUserSegmentationMutation({
 *   variables: {
 *      segmentation: // value for 'segmentation'
 *   },
 * });
 */
export function useUpdateUserSegmentationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSegmentationMutation, UpdateUserSegmentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSegmentationMutation, UpdateUserSegmentationMutationVariables>(UpdateUserSegmentationDocument, options);
      }
export type UpdateUserSegmentationMutationHookResult = ReturnType<typeof useUpdateUserSegmentationMutation>;
export type UpdateUserSegmentationMutationResult = Apollo.MutationResult<UpdateUserSegmentationMutation>;
export type UpdateUserSegmentationMutationOptions = Apollo.BaseMutationOptions<UpdateUserSegmentationMutation, UpdateUserSegmentationMutationVariables>;
export const UpdateWorkoutGameDocument = gql`
    mutation updateWorkoutGame($workoutId: ID!, $workoutSlot: Int!, $newGameSlug: String!) {
  updateWorkoutGame(
    workoutId: $workoutId
    workoutSlot: $workoutSlot
    newGameSlug: $newGameSlug
  ) {
    id
    slots {
      ...WorkoutSlots
    }
  }
}
    ${WorkoutSlotsFragmentDoc}`;
export type UpdateWorkoutGameMutationFn = Apollo.MutationFunction<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>;

/**
 * __useUpdateWorkoutGameMutation__
 *
 * To run a mutation, you first call `useUpdateWorkoutGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkoutGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkoutGameMutation, { data, loading, error }] = useUpdateWorkoutGameMutation({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      workoutSlot: // value for 'workoutSlot'
 *      newGameSlug: // value for 'newGameSlug'
 *   },
 * });
 */
export function useUpdateWorkoutGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>(UpdateWorkoutGameDocument, options);
      }
export type UpdateWorkoutGameMutationHookResult = ReturnType<typeof useUpdateWorkoutGameMutation>;
export type UpdateWorkoutGameMutationResult = Apollo.MutationResult<UpdateWorkoutGameMutation>;
export type UpdateWorkoutGameMutationOptions = Apollo.BaseMutationOptions<UpdateWorkoutGameMutation, UpdateWorkoutGameMutationVariables>;
export const ActivateStripeSubscriptionDocument = gql`
    mutation activateStripeSubscription($input: StripeSessionInput!) {
  activateStripeSubscription(input: $input) {
    id
  }
}
    `;
export type ActivateStripeSubscriptionMutationFn = Apollo.MutationFunction<ActivateStripeSubscriptionMutation, ActivateStripeSubscriptionMutationVariables>;

/**
 * __useActivateStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateStripeSubscriptionMutation, { data, loading, error }] = useActivateStripeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateStripeSubscriptionMutation, ActivateStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateStripeSubscriptionMutation, ActivateStripeSubscriptionMutationVariables>(ActivateStripeSubscriptionDocument, options);
      }
export type ActivateStripeSubscriptionMutationHookResult = ReturnType<typeof useActivateStripeSubscriptionMutation>;
export type ActivateStripeSubscriptionMutationResult = Apollo.MutationResult<ActivateStripeSubscriptionMutation>;
export type ActivateStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ActivateStripeSubscriptionMutation, ActivateStripeSubscriptionMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation createStripeCheckoutSession($input: StripeCheckoutSessionInput!) {
  createStripeCheckoutSession(input: $input) {
    url
  }
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const CreateStripeCustomCheckoutSessionDocument = gql`
    mutation createStripeCustomCheckoutSession($input: StripeCustomCheckoutSessionInput!) {
  createStripeCustomCheckoutSession(input: $input) {
    id
    clientSecret
  }
}
    `;
export type CreateStripeCustomCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCustomCheckoutSessionMutation, CreateStripeCustomCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCustomCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCustomCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCustomCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCustomCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCustomCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCustomCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCustomCheckoutSessionMutation, CreateStripeCustomCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCustomCheckoutSessionMutation, CreateStripeCustomCheckoutSessionMutationVariables>(CreateStripeCustomCheckoutSessionDocument, options);
      }
export type CreateStripeCustomCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCustomCheckoutSessionMutation>;
export type CreateStripeCustomCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCustomCheckoutSessionMutation>;
export type CreateStripeCustomCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCustomCheckoutSessionMutation, CreateStripeCustomCheckoutSessionMutationVariables>;
export const namedOperations = {
  Query: {
    RaindropInsight: 'RaindropInsight',
    ConsolidatedGamePlayCounts: 'ConsolidatedGamePlayCounts',
    TrainOfThoughtInsight: 'TrainOfThoughtInsight',
    LostInMigrationInsight: 'LostInMigrationInsight',
    EbbAndFlowInsights: 'EbbAndFlowInsights',
    DisillusionInsight: 'DisillusionInsight',
    GetAccountState: 'GetAccountState',
    GetBraintreeClientToken: 'GetBraintreeClientToken',
    GetCurrentUser: 'GetCurrentUser',
    GetDemographicInput: 'GetDemographicInput',
    GetFitTestProgress: 'GetFitTestProgress',
    GetGameProgress: 'GetGameProgress',
    GetGamesUnlocked: 'GetGamesUnlocked',
    GetInvitation: 'GetInvitation',
    GetLpisAndPercentiles: 'GetLpisAndPercentiles',
    GetGameRankLpis: 'GetGameRankLpis',
    GetMostImprovedGame: 'GetMostImprovedGame',
    GetConsolidatedGameDatedLpi: 'GetConsolidatedGameDatedLpi',
    GetNotificationSettings: 'GetNotificationSettings',
    GetPaymentHistoryLinkDetails: 'GetPaymentHistoryLinkDetails',
    GetPlans: 'GetPlans',
    GetPromotion: 'GetPromotion',
    GetStreaks: 'GetStreaks',
    GetStripePaymentMethodUpdateLink: 'GetStripePaymentMethodUpdateLink',
    GetTodaysWorkout: 'GetTodaysWorkout',
    GetTodaysAvailableWorkouts: 'GetTodaysAvailableWorkouts',
    GetUser: 'GetUser',
    GlobalGamesConfig: 'GlobalGamesConfig'
  },
  Mutation: {
    addCocosGamePlay: 'addCocosGamePlay',
    AddDependentUserToSubscription: 'AddDependentUserToSubscription',
    AddUnityGamePlay: 'AddUnityGamePlay',
    checkDeferredSubscription: 'checkDeferredSubscription',
    CreateBraintreeSubscription: 'CreateBraintreeSubscription',
    RemoveDependentUserFromSubscription: 'RemoveDependentUserFromSubscription',
    RequestAccountDeletion: 'RequestAccountDeletion',
    SetReminderScheduleActive: 'SetReminderScheduleActive',
    UpdateAutoRenewalStatus: 'UpdateAutoRenewalStatus',
    UpdateBirthdate: 'UpdateBirthdate',
    UpdateDemographics: 'UpdateDemographics',
    UpdateLastUsedLanguage: 'UpdateLastUsedLanguage',
    UpdateMarketingPreferences: 'UpdateMarketingPreferences',
    UpdateNoEnglishFluencyGames: 'UpdateNoEnglishFluencyGames',
    UpdateReminderSchedule: 'UpdateReminderSchedule',
    UpdateUserSegmentation: 'UpdateUserSegmentation',
    updateWorkoutGame: 'updateWorkoutGame',
    activateStripeSubscription: 'activateStripeSubscription',
    createStripeCheckoutSession: 'createStripeCheckoutSession',
    createStripeCustomCheckoutSession: 'createStripeCustomCheckoutSession'
  },
  Fragment: {
    GameProgress: 'GameProgress',
    TopScore: 'TopScore',
    LpiSummary: 'LpiSummary',
    WorkoutGame: 'WorkoutGame',
    WorkoutSlots: 'WorkoutSlots',
    LumosSubscription: 'LumosSubscription',
    DependentSubscription: 'DependentSubscription'
  }
}