import React, { useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'
import getConfig from 'next/config'

import { IPostLumosEventRequest, useLumosEventApi } from '~/events/clients/useLumosEventApi'
import { EventsContext, EventsContextInterface } from '~/events/EventsContext'
import { useEventsInfo } from '~/events/useEventsInfo'
import { getEventAnonymousId } from '~/events/utils/eventAnonymousId'
import { getEventDeviceId } from '~/events/utils/eventDeviceId'
import { getEventSessionId } from '~/events/utils/eventSessionId'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import useLocale from '~/hooks/useLocale'
import { useUserAgentInfo } from '~/hooks/useUserAgentInfo'
import dayjs from '~/libs/dayjs'
import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()

type LumosEventSendFunction = EventsContextInterface['clients']['lumosEventApi']['track']

const EventsProvider: React.FunctionComponent = ({ children }) => {
  const { getEventInfo } = useEventsInfo()
  const { locale } = useLocale()
  const { uaResults } = useUserAgentInfo()
  const [currentScreen, setCurrentScreen] = React.useState<string>('')
  const currentUser = useReactiveVar(currentUserVar)

  const { postLumosEvent } = useLumosEventApi()

  useEffect(() => {
    try {
      // adding context in rollbar for debugging
      const context = {
        user_id: currentUser?.id || null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: dayjs().format('Z'),
        locale: locale,
        device_id: getEventDeviceId(),
        anonymous_id: getEventAnonymousId(currentUser),
        session_id: getEventSessionId(currentUser),
        app_version: publicRuntimeConfig.appVersion,
        user_agent: uaResults.ua,
      }

      logger.clientLog('[Event] Debug::context::log', context) // this should get printed in production

      window?.Rollbar?.configure?.({
        payload: {
          person: {
            id: context.user_id,
          },
          // don't use 'context' key in rollbar, it will be trimmed for 255 characters
          event_context: context,
        },
      })
      // https://help.hotjar.com/hc/en-us/articles/360038394053-How-to-Set-Up-User-Attributes
      window?.hj?.('identify', context.user_id, context)
    } catch (e) {
      logger.error('[Event] Debug::context::catch', e)
    }
  }, [locale, currentUser, uaResults.ua])

  const sendLumosEvents: LumosEventSendFunction = (eventName, payload) => {
    const timeStamp = Date.now()
    const apiPayload: IPostLumosEventRequest = {
      name: eventName,
      local_timestamp: timeStamp,
      // sent_timestamp is the time when the event was sent to the server
      // for now we are using the same timestamp as the event_timestamp
      // Api needs this information because currently its required mostly for mobile apps,
      // in future, if we adds feature of queue or offline support in web
      // then we need to update this timestamp & sent_timestamp logic accordingly
      sent_timestamp: timeStamp,
      payload: payload,
      ...getEventInfo(),
    }

    // don't print all properties in production, we can get them from rollbar context
    // in case of error, rollbar trims the payload to 128KB as whole, https://github.com/rollbar/rollbar.js/issues/629
    // we are capturing necessary details in rollbar context
    logger.debug(`[Event] [${apiPayload.name}]`, apiPayload.payload)
    postLumosEvent(apiPayload)
  }

  /**
   * Showcasing how multiple vendor can send events
   * const sendAmplitudeEvent = () =>{
   *   // example of how amplitude events can get sent
   * }
   * const sendGoogleTagManagerEvent = () =>{
   *   // example of how amplitude events can get sent
   * }
   */

  return (
    <EventsContext.Provider
      value={{
        clients: {
          lumosEventApi: {
            track: sendLumosEvents,
          },
          /**
           *  example of how same clients interface can utilize multiple vendors
           *  amplitude:{
           *    sendEvent: sendAmplitudeEvent
           *  }
           */
        },
        currentScreen,
        setCurrentScreen,
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}

export default EventsProvider
