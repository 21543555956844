import { useCallback } from 'react'

import { useReactiveVar } from '@apollo/client'
import { snakeCase } from 'lodash'
import getConfig from 'next/config'

import { IPostLumosEventRequest } from '~/events/clients/useLumosEventApi'
import { getEventAnonymousId } from '~/events/utils/eventAnonymousId'
import { getEventDeviceId } from '~/events/utils/eventDeviceId'
import { getEventSessionId } from '~/events/utils/eventSessionId'
import { currentUserVar } from '~/graphql/reactive-vars'
import useLocale from '~/hooks/useLocale'
import { useUserAgentInfo } from '~/hooks/useUserAgentInfo'
import dayjs from '~/libs/dayjs'

const { publicRuntimeConfig } = getConfig()

export const useEventsInfo = () => {
  const { locale } = useLocale()
  const { uaResults } = useUserAgentInfo()
  const currentUser = useReactiveVar(currentUserVar)

  const getEventInfo = useCallback(() => {
    // caller will be responsible for populate required fields
    const apiPayload: Omit<IPostLumosEventRequest, 'name' | 'payload' | 'local_timestamp' | 'sent_timestamp'> = {
      user_id: currentUser?.id,
      channel: 'web',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone_offset: dayjs().format('Z'),
      locale: locale,
      locale_requested: globalThis?.navigator?.language ?? '',
      device_id: getEventDeviceId(),
      anonymous_id: getEventAnonymousId(currentUser),
      session_id: getEventSessionId(currentUser),
      app: {
        name: 'lumosity2_web',
        platform: snakeCase(uaResults.device.type || 'desktop'),
        version: publicRuntimeConfig.appVersion,
      },
      device: {
        model: uaResults.device.model || '',
        manufacturer: uaResults.device.vendor || '',
      },
      os: {
        name: snakeCase(uaResults.os.name || ''),
        version: uaResults.os.version || '',
      },
      browser: {
        name: uaResults.browser.name,
        version: uaResults.browser.version,
        user_agent: uaResults.ua,
        screen_height: window.innerHeight,
        screen_width: window.innerWidth,
        device_pixel_ratio: window.devicePixelRatio,
        url: globalThis?.location?.href,
        preferences: (globalThis?.navigator?.languages || []).join(','),
      },
      screen: {
        height: window.screen.height,
        width: window.screen.width,
      },
    }
    return apiPayload
  }, [
    locale,
    currentUser,
    uaResults.browser.name,
    uaResults.browser.version,
    uaResults.device.model,
    uaResults.device.type,
    uaResults.device.vendor,
    uaResults.os.name,
    uaResults.os.version,
    uaResults.ua,
  ])

  return { getEventInfo }
}
