const { missingKeys } = require('./src/utils/i18nLoggerUtils.js')
/*
- Strings that shouldn't be translated go in en-only, otherwise they go in common.
- Nest strings in the section named for the page they are used on, unless they are used on multiple pages,
in which case nest them under the common section within the common file.
*/
/** @type {import('next-translate').I18nConfig} */
module.exports = {
  locales: ['en', 'de', 'es', 'fr', 'ja', 'ko', 'pt'],
  defaultLocale: 'en',
  localeDetection: true,
  defaultNS: 'common',
  pages: {
    '*': ['common', 'en-only'],
    '/family-invitation/*': ['common', 'en-only'],
    '/payment-policy': ['legal'],
    '/privacy-policy': ['legal'],
    '/terms-of-service': ['legal'],
    '/insights/train-of-thought': ['common', 'insights'],
    '/insights/disillusion': ['insights'],
    '/insights/ebb-and-flow': ['insights'],
    '/insights/raindrops': ['common', 'insights'],
    '/insights': ['common', 'insights'],
    '/insights/lost-in-migration': ['insights'],
  },
  logger: missingKeys,
}
