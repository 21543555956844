import { keyBy } from 'lodash'

import { UNKNOWN_STAT_KEY, AllSupportedLanguages } from '~/constants'
import { VersionedGame } from '~/graphql/generated/schema'

// This library defined which games show up on the Games page and are available to be included in a workout
// The game slug must also be included in the backend shared/constants file gamesLibrary in order for game scores to be saved and stats to load.
// The area slug for each game is determined in that same backend gamesLibrary

// This library determines the following:
// assetsPath determines the location of the game file in AWS
// gamesPageImage and heroImage (the thumbnail and hero images) are located in the public/assets folder and the URL is coded here
// bestStatLabelKey is used to look up the translation key for the stat name, e.g. Level or Cards, the translations are in common.json under game.gameStats.statLabel
// isStatLabelFirst determines whether the stat label is placed before or after the stat number
const avatarBasePath = '/assets/images/games/avatars'
const heroBasePath = '/assets/images/games/hero'
const thumbnailBasePath = '/assets/images/games/thumbnail'

const GamesLibrary: Array<GameConfig> = [
  {
    slug: 'assist-ants',
    id: '779f5138-4e78-4f64-81b5-4ac685d9639a',
    gamesPageImage: `${thumbnailBasePath}/assist-ants.svg`,
    heroImage: `${heroBasePath}/assist-ants.svg`,
    avatarImage: `${avatarBasePath}/assist-ants.svg`,
    color: '#0E2337',
    version: '4.11.12',
    assetsPath: 'AssistAnts/4.11.12/release',
    bestStatLabelKey: 'seeds-collected',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'brain-shift',
    id: 'de23e83f-59ec-4338-a727-f88331cdb819',
    gamesPageImage: `${thumbnailBasePath}/brain-shift.svg`,
    heroImage: `${heroBasePath}/brain-shift.svg`,
    avatarImage: `${avatarBasePath}/brain-shift.svg`,
    color: '#005940',
    version: '4.12.10',
    assetsPath: 'BrainShift/4.12.10/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
    localeConfig: {
      ja: {
        gamesPageImage: `${thumbnailBasePath}/brain-shift-ja.svg`,
        avatarImage: `${avatarBasePath}/brain-shift-ja.svg`,
      },
      ko: {
        gamesPageImage: `${thumbnailBasePath}/brain-shift-ko.svg`,
        avatarImage: `${avatarBasePath}/brain-shift-ko.svg`,
      },
    },
  },
  {
    slug: 'chalkboard-challenge',
    id: 'b098f5c2-49bd-4d7e-a433-ed927236c614',
    gamesPageImage: `${thumbnailBasePath}/chalkboard-challenge.svg`,
    heroImage: `${heroBasePath}/chalkboard-challenge.svg`,
    avatarImage: `${avatarBasePath}/chalkboard-challenge.svg`,
    color: '#005940',
    version: '4.9.17',
    assetsPath: 'ChalkboardChallenge/4.9.17/release',
    bestStatLabelKey: 'problems',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'cognition-kitchen',
    id: '59fa2cc4-1785-49ad-9f52-4e35077f9879',
    gamesPageImage: `${thumbnailBasePath}/cognition-kitchen.svg`,
    heroImage: `${heroBasePath}/cognition-kitchen.svg`,
    avatarImage: `${avatarBasePath}/cognition-kitchen.svg`,
    color: '#0e2337',
    version: '4.12.5',
    assetsPath: 'CognitionKitchen/4.12.5/release',
    bestStatLabelKey: 'recipe-steps',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'color-match',
    id: '9902aecd-bc17-4562-8de7-808812cee3b3',
    gamesPageImage: `${thumbnailBasePath}/color-match.svg`,
    heroImage: `${heroBasePath}/color-match.svg`,
    avatarImage: `${avatarBasePath}/color-match.svg`,
    color: '#0E2337',
    version: '4.11.13',
    assetsPath: 'ColorMatch/4.11.13/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
    localeConfig: {
      fr: {
        gamesPageImage: `${thumbnailBasePath}/color-match-fr.svg`,
        avatarImage: `${avatarBasePath}/color-match-fr.svg`,
      },
      pt: {
        gamesPageImage: `${thumbnailBasePath}/color-match-pt.svg`,
        avatarImage: `${avatarBasePath}/color-match-pt.svg`,
      },
      ko: {
        gamesPageImage: `${thumbnailBasePath}/color-match-ko.svg`,
        avatarImage: `${avatarBasePath}/color-match-ko.svg`,
      },
      ja: {
        gamesPageImage: `${thumbnailBasePath}/color-match-ja.svg`,
        avatarImage: `${avatarBasePath}/color-match-ja.svg`,
      },
    },
  },
  {
    slug: 'contextual',
    id: '0eb7d1de-bffc-46da-8fcd-8583d25c7ec5',
    gamesPageImage: `${thumbnailBasePath}/contextual.svg`,
    heroImage: `${heroBasePath}/contextual.svg`,
    avatarImage: `${avatarBasePath}/contextual.svg`,
    color: '#0E2337',
    version: '4.11.0',
    assetsPath: 'Contextual/4.11.0/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: false,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'continuum',
    id: '3bad5358-e900-4ab0-b69d-c9f760e5c0b7',
    gamesPageImage: `${thumbnailBasePath}/continuum.svg`,
    heroImage: `${heroBasePath}/continuum.svg`,
    avatarImage: `${avatarBasePath}/continuum.svg`,
    color: '#094441',
    version: '4.11.13',
    assetsPath: 'Continuum/4.11.13/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: true,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'disillusion',
    id: 'cb129d45-fac4-4fe5-bcd1-75d8b9ccc67d',
    gamesPageImage: `${thumbnailBasePath}/disillusion.svg`,
    heroImage: `${heroBasePath}/disillusion.svg`,
    avatarImage: `${avatarBasePath}/disillusion.svg`,
    color: '#054450',
    version: '4.10.13',
    assetsPath: 'Disillusion/4.10.13/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'eagle-eye',
    id: '93ba9128-2e52-43c9-aec8-5d4c1c2af287',
    gamesPageImage: `${thumbnailBasePath}/eagle-eye.svg`,
    heroImage: `${heroBasePath}/eagle-eye.svg`,
    avatarImage: `${avatarBasePath}/eagle-eye.svg`,
    color: '#48A77A',
    version: '3.0.10',
    assetsPath: 'EagleEye_CC/web/3.0.10_2022_10_21/ad93df775168cd2073e22323ae2dec232fa68618/673123576/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    // TODO: use this once the game is updated with the correct metadata
    // bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'ebb-and-flow',
    id: 'e4250024-805d-43d3-93f0-9352b1621117',
    gamesPageImage: `${thumbnailBasePath}/ebb-and-flow.svg`,
    heroImage: `${heroBasePath}/ebb-and-flow.svg`,
    avatarImage: `${avatarBasePath}/ebb-and-flow.svg`,
    color: '#003C82',
    version: '4.10.9',
    assetsPath: 'EbbAndFlow/4.10.9/release',
    bestStatLabelKey: 'leaves',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'editors-choice',
    id: '6fc485e6-568c-439a-8db5-e341de3600c6',
    gamesPageImage: `${thumbnailBasePath}/editors-choice.svg`,
    heroImage: `${heroBasePath}/editors-choice.svg`,
    avatarImage: `${avatarBasePath}/editors-choice.svg`,
    color: '#413834',
    version: '4.11.5',
    assetsPath: 'EditorsChoice/4.11.5/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'familiar-faces',
    id: '9c6e5307-3162-413f-acf7-c88e6f08522b',
    gamesPageImage: `/assets/images/default-game-card.svg`,
    heroImage: ``,
    avatarImage: `/assets/images/default-game-avatar.svg`,
    color: '#0e2337',
    version: '3.0.5',
    assetsPath: 'FamiliarFaces_CC/master/2d69537dc35ad0177496c9f81d92a71175cdde67/948384993/release',
    bestStatLabelKey: 'restaurant',
    isStatLabelFirst: true,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'feel-the-beat',
    id: 'd083bcd3-097b-42de-bdc8-cfb14a8f0eaa',
    gamesPageImage: `${thumbnailBasePath}/feel-the-beat.svg`,
    heroImage: `${heroBasePath}/feel-the-beat.svg`,
    avatarImage: `${avatarBasePath}/feel-the-beat.svg`,
    color: '#3B2F84',
    version: '4.11.14',
    assetsPath: 'FeelTheBeat/4.11.14/release',
    bestStatLabelKey: 'beat-streak',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'follow-that-frog',
    id: 'b19cc4d7-5c1f-4a87-b646-d3d8c9db97cb',
    gamesPageImage: `${thumbnailBasePath}/follow-that-frog.svg`,
    heroImage: `${heroBasePath}/follow-that-frog.svg`,
    avatarImage: `${avatarBasePath}/follow-that-frog.svg`,
    color: '#0e2337',
    version: '4.22.9',
    assetsPath: 'FollowThatFrog/4.22.9/release',
    bestStatLabelKey: 'jumps-ahead',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'fuse-clues',
    id: 'cc99d210-e71c-4028-a21a-309779329403',
    gamesPageImage: `${thumbnailBasePath}/fuse-clues.svg`,
    heroImage: `${heroBasePath}/fuse-clues.svg`,
    avatarImage: `${avatarBasePath}/fuse-clues.svg`,
    color: '#196E6E',
    version: '4.11.10',
    assetsPath: 'FuseClues/4.11.10/release',
    bestStatLabelKey: 'window',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'halve-your-cake',
    id: '74352ed0-9570-44d7-b5a9-2d565eda4172',
    gamesPageImage: `${thumbnailBasePath}/halve-your-cake.svg`,
    heroImage: `${heroBasePath}/halve-your-cake.svg`,
    avatarImage: `${avatarBasePath}/halve-your-cake.svg`,
    color: '#A44522',
    version: '1.1.3',
    assetsPath: 'HalveYourCake_CC/web/1.1.3_2022_10_21/6bf063dbc8c7880b25668dedc1354b9a625c538d/673136127/release',
    bestStatLabelKey: 'dish',
    isStatLabelFirst: true,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'highway-hazards',
    id: '52e6f8e1-5585-49ca-b3e4-33c279463681',
    gamesPageImage: `${thumbnailBasePath}/highway-hazards.svg`,
    heroImage: `${heroBasePath}/highway-hazards.svg`,
    avatarImage: `${avatarBasePath}/highway-hazards.svg`,
    color: '#7D2709',
    version: '4.11.17',
    assetsPath: 'HighwayHazards/4.11.17/release',
    bestStatLabelKey: 'mph',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'lost-in-migration',
    id: '11700bab-fbed-4d0c-8d05-42b696ec133e',
    gamesPageImage: `${thumbnailBasePath}/lost-in-migration.svg`,
    heroImage: `${heroBasePath}/lost-in-migration.svg`,
    avatarImage: `${avatarBasePath}/lost-in-migration.svg`,
    color: '#48A4B7',
    version: '4.10.14',
    assetsPath: 'LostInMigration/4.10.14/release',
    bestStatLabelKey: 'flocks',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'magic-chance',
    id: '520fabd8-01ea-45d5-8923-75a7203d7602',
    gamesPageImage: `${thumbnailBasePath}/magic-chance.svg`,
    heroImage: `${heroBasePath}/magic-chance.svg`,
    avatarImage: `${avatarBasePath}/magic-chance.svg`,
    color: '#374884',
    version: '1.1.1',
    assetsPath:
      'MagicChance_CC/MagicChance_CC/web/1.1.1_2021_05_13/cd143e14a58126c31c6dab45eba87a54a3e06d17/302168409/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'masterpiece',
    id: '99ee8966-a46f-4b54-a352-766c3189573b',
    gamesPageImage: `${thumbnailBasePath}/masterpiece.svg`,
    heroImage: `${heroBasePath}/masterpiece.svg`,
    avatarImage: `${avatarBasePath}/masterpiece.svg`,
    color: '#ee9528',
    version: '4.11.13',
    assetsPath: 'Masterpiece/4.11.13/release',
    bestStatLabelKey: 'pieces',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'memory-match',
    id: 'abe34230-e1b4-446e-8315-0b4e5cf8c3e9',
    gamesPageImage: `${thumbnailBasePath}/memory-match.svg`,
    heroImage: `${heroBasePath}/memory-match.svg`,
    avatarImage: `${avatarBasePath}/memory-match.svg`,
    color: '#196E6E',
    version: '4.12.12',
    assetsPath: 'MemoryMatch/4.12.12/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'memory-matrix',
    id: '3de8b1a9-1309-4a2c-97f5-be0af1aba8a4',
    gamesPageImage: `${thumbnailBasePath}/memory-matrix.svg`,
    heroImage: `${heroBasePath}/memory-matrix.svg`,
    avatarImage: `${avatarBasePath}/memory-matrix.svg`,
    color: '#5A322B',
    version: '4.11.9',
    assetsPath: 'MemoryMatrix/4.11.9/release',
    bestStatLabelKey: 'tiles',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'memory-serves',
    id: 'c03300cb-1ed8-4e10-92b4-93d8f332ad98',
    gamesPageImage: `${thumbnailBasePath}/memory-serves.svg`,
    heroImage: `${heroBasePath}/memory-serves.svg`,
    avatarImage: `${avatarBasePath}/memory-serves.svg`,
    color: '#3C2C3E',
    version: '4.11.2',
    assetsPath: 'MemoryServes/4.11.2/release',
    bestStatLabelKey: 'deliveries',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'organic-order',
    id: '63d01274-12c4-4130-ae7b-5e43bed34cef',
    gamesPageImage: `${thumbnailBasePath}/organic-order.svg`,
    heroImage: `${heroBasePath}/organic-order.svg`,
    avatarImage: `${avatarBasePath}/organic-order.svg`,
    color: '#48A77A',
    version: '4.10.3',
    assetsPath: 'OrganicOrder/4.10.3/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'penguin-rally',
    id: 'e60d3c67-ae4d-44b7-b2b0-3cff5b5983d2',
    gamesPageImage: `${thumbnailBasePath}/penguin-rally.svg`,
    heroImage: `${heroBasePath}/penguin-rally.svg`,
    avatarImage: `${avatarBasePath}/penguin-rally.svg`,
    color: '#70DFDD',
    version: '4.9.5',
    assetsPath: 'PenguinRally/4.9.5/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'pet-detective',
    id: '6b3d672c-2b96-42a3-8893-9693f39f5c74',
    gamesPageImage: `${thumbnailBasePath}/pet-detective.svg`,
    heroImage: `${heroBasePath}/pet-detective.svg`,
    avatarImage: `${avatarBasePath}/pet-detective.svg`,
    color: '#0e2337',
    version: '4.10.14',
    assetsPath: 'PetDetective/4.10.14/release',
    bestStatLabelKey: 'pets-returned',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'pinball-recall',
    id: '3c59f4c5-cdc8-487b-a20b-99ae829829ff',
    gamesPageImage: `${thumbnailBasePath}/pinball-recall.svg`,
    heroImage: `${heroBasePath}/pinball-recall.svg`,
    avatarImage: `${avatarBasePath}/pinball-recall.svg`,
    color: '#001626',
    version: '4.11.8',
    assetsPath: 'PinballRecall/4.11.8/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'pirate-passage',
    id: '0bdcf7a3-2317-41dd-83e8-fa6163909bc8',
    gamesPageImage: `${thumbnailBasePath}/pirate-passage.svg`,
    heroImage: `${heroBasePath}/pirate-passage.svg`,
    avatarImage: `${avatarBasePath}/pirate-passage.svg`,
    color: '#18C4BC',
    version: '3.0.5',
    assetsPath: 'PiratePassage_CC/web/3.0.5_2022_13_07/17defbeac58a77e89b14e4a413056b0eac6274bc/587017483/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'playing-koi',
    id: 'da05d339-75ec-4cb9-a13b-89c00d096018',
    gamesPageImage: `${thumbnailBasePath}/playing-koi.svg`,
    heroImage: `${heroBasePath}/playing-koi.svg`,
    avatarImage: `${avatarBasePath}/playing-koi.svg`,
    color: '#2076BC',
    version: '4.9.14',
    assetsPath: 'PlayingKoi/4.9.14/release',
    bestStatLabelKey: 'fish-fed',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'raindrops',
    id: '22d72145-6f5c-4f4c-9a30-61030d80e10b',
    gamesPageImage: `${thumbnailBasePath}/raindrops.svg`,
    heroImage: `${heroBasePath}/raindrops.svg`,
    avatarImage: `${avatarBasePath}/raindrops.svg`,
    color: '#3AC0D1',
    version: '4.11.32',
    assetsPath: 'Raindrops/4.11.32/release',
    bestStatLabelKey: 'drops',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'river-ranger',
    id: '82e3b087-1d2c-469e-a9ad-eb317a30a8da',
    gamesPageImage: `${thumbnailBasePath}/river-ranger.svg`,
    heroImage: `${heroBasePath}/river-ranger.svg`,
    avatarImage: `${avatarBasePath}/river-ranger.svg`,
    color: '#57B7F2',
    version: '4.11.15',
    assetsPath: 'RiverRanger/4.11.15/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'robot-factory',
    id: 'b53941ee-6c25-4b0a-9828-fd48f8eb159c',
    gamesPageImage: `${thumbnailBasePath}/robot-factory.svg`,
    heroImage: `${heroBasePath}/robot-factory.svg`,
    avatarImage: `${avatarBasePath}/robot-factory.svg`,
    color: '#c07237',
    version: '3.0.5',
    assetsPath: 'RobotFactory_CC/web/3.0.5_2022_09_21/96f8d05a816e3beb614f8787e69f4713a74e975a/646687924/release',
    bestStatLabelKey: 'robots',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'ruled-out',
    id: '191b739c-fd78-4379-a3fb-f08cfc2f4a6b',
    gamesPageImage: `${thumbnailBasePath}/ruled-out.svg`,
    heroImage: `${heroBasePath}/ruled-out.svg`,
    avatarImage: `${avatarBasePath}/ruled-out.svg`,
    color: '#0e2337',
    version: '4.31.11',
    assetsPath: 'RuledOut/4.31.11/release',
    bestStatLabelKey: 'rules-discovered',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'skyrise',
    id: '1b252c4b-a603-4276-ac01-bfdf962bac6f',
    gamesPageImage: `${thumbnailBasePath}/skyrise.svg`,
    heroImage: `${heroBasePath}/skyrise.svg`,
    avatarImage: `${avatarBasePath}/skyrise.svg`,
    color: '#379EA6',
    version: '1.3.0',
    assetsPath: 'ObservationTower_CC/web/1.3.0_2023_09_08/ab7d569025be60e9062fc4f022fbbd9ad3134e68/997884407/release',
    bestStatLabelKey: 'feet',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'space-trace',
    id: '46c8cc4f-243b-46e9-8601-3877722c9c3d',
    gamesPageImage: `${thumbnailBasePath}/space-trace.svg`,
    heroImage: `${heroBasePath}/space-trace.svg`,
    avatarImage: `${avatarBasePath}/space-trace.svg`,
    color: '#000420',
    version: '1.0.7',
    assetsPath: 'DesignFluency_CC/web/1.0.7_2021_08_11/7ad3eb669895135153f27436bf1c915e13d9d251/351628237/release',
    bestStatLabelKey: 'patterns',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'speed-match',
    id: '071c3e4c-962a-4d86-bf61-20a3cbccddc3',
    gamesPageImage: `${thumbnailBasePath}/speed-match.svg`,
    heroImage: `${heroBasePath}/speed-match.svg`,
    avatarImage: `${avatarBasePath}/speed-match.svg`,
    color: '#00446D',
    version: '4.13.12',
    assetsPath: 'SpeedMatch/4.13.12/release',
    bestStatLabelKey: 'cards',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'speed-pack',
    id: 'ddfbbd8a-d832-4f04-b980-eec4e13cfc86',
    gamesPageImage: `${thumbnailBasePath}/speed-pack.svg`,
    heroImage: `${heroBasePath}/speed-pack.svg`,
    avatarImage: `${avatarBasePath}/speed-pack.svg`,
    color: '#B7802D',
    version: '4.9.16',
    assetsPath: 'SpeedPack/4.9.16/release',
    bestStatLabelKey: 'suitcases',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'splitting-seeds',
    id: 'a4962f2b-d102-474d-8cf6-8d384418b2d5',
    gamesPageImage: `${thumbnailBasePath}/splitting-seeds.svg`,
    heroImage: `${heroBasePath}/splitting-seeds.svg`,
    avatarImage: `${avatarBasePath}/splitting-seeds.svg`,
    color: '#00693F',
    version: '3.0.4',
    assetsPath: 'SplittingSeeds_CC/master/2683f1e6129d6445d043880c9aa3a83c794bbbc8/275186282/release',
    bestStatLabelKey: 'seeds',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'star-search',
    id: 'a47fbba8-111e-4720-887f-2f9efe09fd05',
    gamesPageImage: `${thumbnailBasePath}/star-search.svg`,
    heroImage: `${heroBasePath}/star-search.svg`,
    avatarImage: `${avatarBasePath}/star-search.svg`,
    color: '#0E2337',
    version: '3.0.5',
    assetsPath: 'StarSearch_CC/web/3.0.5_2022_05_25/e6009b64a2c933240085e34624b50fecb861e381/547850780/release',
    bestStatLabelKey: 'level',
    isStatLabelFirst: true,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'taking-root',
    id: '1aa29910-7352-4fde-92a6-e134a3345e25',
    gamesPageImage: `${thumbnailBasePath}/taking-root.svg`,
    heroImage: `${heroBasePath}/taking-root.svg`,
    avatarImage: `${avatarBasePath}/taking-root.svg`,
    color: '#6D3226',
    version: '3.0.7',
    assetsPath: 'TakingRoot_CC/web/3.0.7_2021_07_30/f2055f97c56b75f9571d6926274fcb7c13b76631/345567433/release',
    bestStatLabelKey: UNKNOWN_STAT_KEY,
    isStatLabelFirst: true,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'tidal-treasures',
    id: 'cfff978a-5bac-4d60-8fe6-4425e25be224',
    gamesPageImage: `${thumbnailBasePath}/tidal-treasures.svg`,
    heroImage: `${heroBasePath}/tidal-treasures.svg`,
    avatarImage: `${avatarBasePath}/tidal-treasures.svg`,
    color: '#374884',
    version: '4.9.18',
    assetsPath: 'TidalTreasures/4.9.18/release',
    bestStatLabelKey: 'items',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'top-that',
    id: 'aeed5f5b-e4db-4693-abd9-b5ce2f3dd460',
    gamesPageImage: `${thumbnailBasePath}/top-that.svg`,
    heroImage: `${heroBasePath}/top-that.svg`,
    avatarImage: `${avatarBasePath}/top-that.svg`,
    color: '#071C31',
    version: '4.10.3',
    assetsPath: 'TopThat/4.10.3/release',
    bestStatLabelKey: 'top-picks',
    isStatLabelFirst: false,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'train-of-thought',
    id: '3564ab29-642f-4acc-9ee2-c7e3ac552d33',
    gamesPageImage: `${thumbnailBasePath}/train-of-thought.svg`,
    heroImage: `${heroBasePath}/train-of-thought.svg`,
    avatarImage: `${avatarBasePath}/train-of-thought.svg`,
    color: '#2A802B',
    version: '4.10.12',
    assetsPath: 'TrainOfThought/4.10.12/release',
    bestStatLabelKey: 'trains',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'trouble-brewing',
    id: '17b5f376-5ac4-44e7-8e80-508201e62435',
    gamesPageImage: `${thumbnailBasePath}/trouble-brewing.svg`,
    heroImage: `${heroBasePath}/trouble-brewing.svg`,
    avatarImage: `${avatarBasePath}/trouble-brewing.svg`,
    color: '#084C53',
    version: '4.10.12',
    assetsPath: 'TroubleBrewing/4.10.12/release',
    bestStatLabelKey: 'orders',
    isStatLabelFirst: false,
    languages: AllSupportedLanguages,
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'word-bubbles',
    id: 'd0c8791c-f093-472b-9adf-fbf72f4e9db4',
    gamesPageImage: `${thumbnailBasePath}/word-bubbles.svg`,
    heroImage: `${heroBasePath}/word-bubbles.svg`,
    avatarImage: `${avatarBasePath}/word-bubbles.svg`,
    color: '#0E2337',
    version: '4.9.16',
    assetsPath: 'WordBubbles/4.9.16/release',
    bestStatLabelKey: 'words',
    isStatLabelFirst: false,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
  {
    slug: 'word-snatchers',
    id: '1ee24bfa-e2fa-4455-b69b-871e9eaaee32',
    gamesPageImage: `${thumbnailBasePath}/word-snatchers.svg`,
    heroImage: `${heroBasePath}/word-snatchers.svg`,
    avatarImage: `${avatarBasePath}/word-snatchers.svg`,
    color: '#652B7D',
    version: '3.0.4',
    assetsPath: 'WordSnatchers_CC/master/00d31a7bcca5af078c295c4a1d010f5c6e3a78c6/208881198/release',
    bestStatLabelKey: 'letters',
    isStatLabelFirst: false,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: false,
  },
  {
    slug: 'whats-my-name',
    id: '5da6cb2e-c683-437a-98f4-cbc9c6433024',
    gamesPageImage: `${thumbnailBasePath}/whats-my-name.svg`,
    heroImage: `${heroBasePath}/whats-my-name.svg`,
    avatarImage: `${avatarBasePath}/whats-my-name.svg`,
    color: '#0e2337',
    version: '4.20.10',
    assetsPath: 'WhatsMyName/4.20.10/release',
    bestStatLabelKey: 'names',
    isStatLabelFirst: false,
    languages: ['en'],
    isEarlyAccess: false,
    isUnity: true,
  },
]

export const GamesLibraryDictionary = keyBy(GamesLibrary, 'slug') as Record<GameSlug, GameConfig>

export const allVersionedGames: VersionedGame[] = GamesLibrary.map((game) => ({
  gameSlug: game.slug,
  gameVersion: game.version,
}))

export default GamesLibrary
