import { useMemo } from 'react'

import useTranslation from 'next-translate/useTranslation'

import { BASE_COUNT_INTL_WEB_GAMES, BASE_COUNT_EN_WEB_GAMES, AllSupportedLanguages } from '~/constants'

const useLocale = () => {
  const preferredLanguages = navigator.languages
  const { lang: siteLanguage } = useTranslation()

  const supportedLanguage = useMemo(() => {
    return preferredLanguages.find((lang) => (AllSupportedLanguages as string[]).includes(lang))
  }, [preferredLanguages])

  // If the user's browser is on a supported language and that's the language the site is set to,
  // use the browser language which includes the region, otherwise just use the language the site is set to
  const locale: string = supportedLanguage?.startsWith(siteLanguage) ? supportedLanguage : siteLanguage

  // If using a supported language other than English, use the intl web count, otherwise use the EN game count.
  const minNumberOfGamesForLocale = siteLanguage === 'en' ? BASE_COUNT_EN_WEB_GAMES : BASE_COUNT_INTL_WEB_GAMES

  return { locale, minNumberOfGamesForLocale }
}

export default useLocale
