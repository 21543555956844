import { createContext } from 'react'

interface AuthContextValues {
  hasPremium: boolean
  setHasPremium: (hasPremium: boolean) => void
  isFreeTrialEligible: boolean
  setIsFreeTrialEligible: (isFreeTrialEligible: boolean) => void
}

export const AuthContext = createContext<AuthContextValues>({
  hasPremium: false,
  setHasPremium: () => null,
  isFreeTrialEligible: false,
  setIsFreeTrialEligible: () => null,
})
