import { InMemoryCache } from '@apollo/client'

import { playGameSoundsVar, showSideNavVar } from '~/graphql/reactive-vars/uiVar'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'

export const createDefaultCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          /**
           * Populate whole queries
           */

          /* MOCKS ****************************************/
          /* END MOCKS ************************************/

          // potential user query
          GetCurrentUser: {
            read: () => {
              const currentUserVal = currentUserVar()
              const UISettings = {
                showSideNav: showSideNavVar(),
                game_sound_on: playGameSoundsVar(),
              }
              return {
                ...currentUserVal,
                ...UISettings,
              }
            },
          },
        },
      },
      User: {
        fields: {
          lpiSummary: {
            // merge is needed because if there are multiple query calling same keys with different files under it, then cache doesn't know how to merge it
            // example : there are few queries calling lpiSummary but having different fields like `lpisByArea`, `lpisByGame`, `mostImprovedGames`
            merge: true,
            // https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
            // use below code to customize the merge logic if needed.
            // merge(existing = {}, incoming, { mergeObjects }) {
            //   return mergeObjects(existing, incoming)
            // },
          },
          gameInsight: {
            merge: true,
          },
        },
      },
    },
  })
}

export const cache: InMemoryCache = createDefaultCache()
