import { PlayContext } from '~/constants'

export type EventNameValue =
  | 'screen_view'
  | 'object_click'
  | 'sign_out'
  | 'game_start'
  | 'game_abort'
  | 'game_finish'
  | 'game_output'
  | 'game_crash'
  | 'workout_start'
  | 'workout_finish'
  | 'workout_game_swap'
  | 'email_training_reminders_select'
  | 'error_display'
  | 'error_occur'
  | 'unsupported_browser_error'
  | 'fit_test_start'
  | 'fit_test_finish'
  | 'ob_iframe_launch' // user before obi iframe is launched
  | 'client_sign_in'
  | 'ld_segmentation' // launch darkly segmentation

export type HandShakeContextType = 'landing' | 'questions' | 'post_fit_test'

export type EventScreenNameValues =
  | 'intro'
  | 'login'
  | 'reset_password'
  | 'reset_password_email_sent'
  | 'create_account'
  | 'auth_generic_error'
  | 'onboarding_start'
  | 'onboarding_gender'
  | 'onboarding_education'
  | 'onboarding_profession'
  | 'onboarding_referral'
  | 'fit_test_start'
  | 'fit_test_percentile'
  | 'fit_test_science'
  | 'fit_test_results'
  | 'fit_test_calculating_results'
  | 'score'
  | 'streak'
  | 'email_training_reminders'
  | 'paywall'
  | 'badge_unlock'
  | 'plan_selection'
  | 'payment_method'
  | 'checkout'
  | 'home'
  | 'game'
  | 'stats'
  | 'settings'
  | 'pregame'
  | 'game_player'
  | 'postgame'
  | 'workout_complete'
  | 'payment_confirmation'
  | 'account_birthdate'
  | 'mid_workout_premium_upsell'
  | 'billing_information'
  | 'log_out'
  | 'game_locked'
  | 'workout_swap'
  | 'notifications_disabled'
  | 'terms_of_service'
  | 'privacy_policy'
  | 'california_privacy'
  | 'forgot_password'
  | 'forgot_password_passcode'
  | 'create_account_eligibility_message'
  | 'family_plan_removal_confirmation'
  | 'family_plan_management'
  | 'help_center' // <- external page
  | 'stripe_checkout' // <- external page
  | 'reminder_confirmation' // <- external page
  | 'game_lpi_rankings'
  | 'most_improved_games'
  | 'game_lpi_chart'
  | 'insight_train_of_thought_about_ranks'
  | 'checkout_exit_popup'
  | 'payment_policy'
  | 'insights'
  | 'insight_lost_in_migration_about_filtering_styles'
  | 'insights_learn_more'
  | `insight_raindrops_strategy_${string}` // <- dynamic, this string has category like multiplying_by_1 | adding_with_carrying
  | `insight_${InsightCardsCategory}`
  | 'insights_unity_cocos_popup'
  | 'quit_game_modal'

/**
 * As same component can be used in multiple places,
 * we need to track the context of the game
 */
export const EventGameContext = {
  Games: 'games',
  Workout: 'workout',
  FitTest: 'fittest',
} as const

export type EventGameContextValues = ValueOf<typeof EventGameContext>

/**
 * Why PlayContext to EventGameContext map is needed?
 * PlayContext represents code Enum
 * EventGameContext represents what we need to sent to the events
 */
export const PlayContextToGameContextMap = {
  [PlayContext.Workout]: EventGameContext.Workout,
  [PlayContext.FitTest]: EventGameContext.FitTest,
  [PlayContext.FreePlay]: EventGameContext.Games,
} as const

// Not using enum or const helps in better tree shaking.
// if we use types, then we can directly use these string instead of importing huge constants
// type will make sure that its not part of build & we still be able to get type safety
export type EventClickTypeValues = 'button' | 'banner' | 'link' | 'single_select' | 'carousel'

export type InsightCardsCategory =
  | 'train_of_thought'
  | 'disillusion'
  | 'lost_in_migration'
  | 'raindrops'
  | 'ebb_and_flow'
  | 'most_improved_games'
  | 'game_lpi_rankings'

type InsightCardsAction = 'view_insight' | 'unlock_with_premium' | 'play'

export type EventClickNamesValues =
  | 'subscribe'
  | 'payment_method_card'
  | 'payment_method_paypal'
  | 'plan_selection_web_monthly'
  | 'plan_selection_web_yearly'
  | 'plan_selection_web_biennially'
  | 'plan_selection_web_lifetime'
  | 'plan_selection_web_plan_type_toggle'
  | 'payment_confirmation_start_training'
  | 'home_workout_play'
  | 'workout_complete_acknowledge'
  | 'play_workout'
  | 'pregame_workout_game_swap'
  | 'mid_workout_premium_upsell_subscribe'
  | 'mid_workout_premium_upsell_skip'
  | 'pregame_play'
  | 'pregame_how_to_play'
  | 'postgame_continue'
  | 'workout_complete'
  | 'login_email_password'
  | 'login_google'
  | 'login_facebook'
  | 'login_apple'
  | 'create_account_email_signup'
  | 'create_account_google'
  | 'create_account_facebook'
  | 'create_account_apple'
  | 'onboard_begin'
  | 'onboard_gender_skip'
  | 'onboard_gender_back'
  | 'onboard_gender_next'
  | 'onboard_education_skip'
  | 'onboard_education_back'
  | 'onboard_education_next'
  | 'onboard_profession_skip'
  | 'onboard_profession_back'
  | 'onboard_profession_next'
  | 'onboard_referral_skip'
  | 'onboard_referral_back'
  | 'onboard_referral_next'
  | 'onboard_pregame_next'
  | 'onboard_pregame_back'
  | 'fit_test_begin'
  | 'play_fit_test'
  | 'fit_test_welcome_next'
  | 'fit_test_percentile_next'
  | 'fit_test_science_next'
  | 'fit_test_results_next'
  | 'streak_acknowledgement'
  | 'badge_unlock_acknowledgement'
  | 'email_training_reminder_set'
  | 'email_training_reminder_later'
  | 'home'
  | 'games'
  | 'stats'
  | 'settings'
  | 'game_player_play'
  | 'game_player_how_to'
  | 'game_locked_close'
  | 'account_birthdate_continue'
  | 'workout_upsell_learn_about_premium'
  | 'workout_upsell_subscribe'
  | 'workout_upsell_back'
  | 'payment_method_manage'
  | 'payment_method_auto_renewal'
  | 'workout_swap'
  | 'visit_helpdesk'
  | 'manage_autorenewing_subscription'
  | 'turn_on_autorenewing_subscription'
  | 'turn_off_autorenewing_subscription'
  | 'cancel_turn_off_autorenewing_subscription'
  | 'contact_us'
  | 'play_newest_game'
  | 'turn_on_training_reminders'
  | 'contact_customer_support'
  | 'privacy_policy'
  | 'california_privacy'
  | 'terms_of_service'
  | 'log_out'
  | 'log_out_confirm'
  | 'log_out_cancel'
  | 'go_to_games'
  | 'learn_about_premium'
  | 'login_forgot_password'
  | 'forgot_password_submit_email'
  | 'forgot_password_go_back'
  | 'forgot_password_submit_passcode'
  | 'forgot_password_resend_code'
  | 'payment_confirmation_acknowledge'
  | 'billing_information_payment_method_manage'
  | 'billing_information_auto_renewal_manage'
  | 'cancel_free_trial'
  | 'paywall_choose_a_plan'
  | 'paywall_continue_with_limited'
  | 'postgame_play_again'
  | 'stats_lpi_card_tooltip'
  | 'stats_lpi_card_area_click' // <- subjected to change
  | 'stats_lpi_percentile_card_tooltip'
  | 'stats_lpi_percentile_card_unlock'
  | 'stats_game_lpi_ranking_card'
  | 'stats_game_lpi_ranking_card_unlock'
  | 'game_lpi_rankings_filter'
  | 'game_lpi_rankings_sort'
  | 'game_lpi_rankings_card'
  | 'stats_most_improved_games_card'
  | 'stats_most_improved_games_card_unlock'
  | 'most_improved_games_filter'
  | 'most_improved_games_sort'
  | 'most_improved_games_card'
  | 'game_lpi_chart_play'
  | 'insight_train_of_thought_play'
  | 'insight_train_of_thought_tab'
  | 'insight_train_of_thought_about_ranks'
  | 'insight_train_of_thought_planning_carousel_forward'
  | 'insight_train_of_thought_planning_carousel_backward'
  | 'checkout_payment_method'
  | 'checkout_view_plans'
  | 'checkout_close'
  | 'checkout_close_popup_continue'
  | 'checkout_close_popup_exit'
  | 'payment_policy'
  | 'insight_disillusion_play'
  | 'insights'
  | 'insight_ebb_and_flow_play'
  | 'insight_lost_in_migration_play'
  | 'insight_lost_in_migration_tab'
  | 'insight_lost_in_migration_other_styles'
  | 'insights_learn_more'
  | 'insights_learn_more_cta'
  | 'insights_get_premium_today'
  | 'insight_raindrops_play'
  | 'insight_raindrops_strategy'
  | `insights_page_${InsightCardsCategory}_${InsightCardsAction}`
  | `insight_raindrops_strategy_${string}` // <- dynamic, this string has category like multiplying_by_1 | adding_with_carrying
  | 'insights_unity_cocos_popup_cta'
  | 'quit_game_modal_cta'

export const ErrorEventTypes = {
  AccountRegisterFail: 'account_register_fail',
  SignInFail: 'sign_in_fail',
  LogOutFail: 'log_out_fail',
  ForceLogoutFail: 'force_log_out_fail',
  AccountRecoveryFail: 'account_recovery_fail',
  DobUpdateFail: 'dob_update_fail',
  AccessTokenToJwtExchangeFail: 'access_token_to_jwt_exchange_fail',
  StripeFail: 'stripe_fail',
} as const

export type ErrorEventTypesValues = ValueOf<typeof ErrorEventTypes> | 'generic_error'

export type CommonGameEventProps = {
  game_slug?: string
  game_session_id?: string
  workout_id?: string
  is_swapped?: boolean
  is_replay?: boolean
  game_context?: EventGameContextValues
  fit_variant?: 'result_post_fit' // to A/B test fit test flows
  position?: number
}
