/**
 * The useTrackEvents hook is used to track specific events that don't fit into the other tracking hooks.
 * Eg: SignOut, EmailTrainingReminder
 * Think of it as a place where you would want to implement miscellaneous tracking events.
 */
import { useCallback, useContext } from 'react'

import UAParser from 'ua-parser-js'

import { EventsContext } from '~/events/EventsContext'
import {
  CommonGameEventProps,
  ErrorEventTypesValues,
  EventClickNamesValues,
  EventClickTypeValues,
  EventNameValue,
  EventScreenNameValues,
  HandShakeContextType,
} from '~/events/eventTypes'
import logger from '~/utils/logger'

type EmailTrainingReminderType = {
  is_training_reminders: boolean
  training_reminders_days: string[]
  training_reminders_time: string
}

type ErrorDisplayType = {
  screen_name: string
  error_message: string
}

type ErrorOccurEventType = {
  screen_name: EventScreenNameValues
  error_type: ErrorEventTypesValues
  error_location: string
  error_message?: string
  error_details?: any
  click_name?: EventClickNamesValues
  game_slug?: string
}

type UnsupportedBrowserErrorType = {
  user_agent: UAParser.IResult
  screen_name: EventScreenNameValues
  is_webgl2_available: boolean
  is_html5_available: boolean
}

type DefaultPayloadType = Record<string, any>

type FitTestStatusType = {
  game_slug: GameSlug
  game_slugs: GameSlug[]
  game_session_id?: string
  fit_variant?: 'result_post_fit'
}

type WorkoutStartEventType = {
  workout_id: string
  game_slugs: string[]
  workout_mode: WorkoutModeTypes
  algorithm: string
}

type WorkoutFinishEventType = {
  workout_id: string
  game_slugs: string[]
  workout_mode: WorkoutModeTypes
}

type WorkoutSwapEventType = {
  workout_id: string
  previous_game_slug: string
  current_game_slug: string
}

type GameCrashEventType = CommonGameEventProps & {
  error_location: string
  error_message: string
  error_data: any
}

type CocosGameOutputType = CommonGameEventProps & {
  score: number
  spec_name: string
  game_result_data: any
  user_level: number
  session_level: number
}

type UnityGameOutputType = CommonGameEventProps & {
  play_data: any
  type: string
}

type GameOutputType = CocosGameOutputType | UnityGameOutputType

type GameAbortType = CommonGameEventProps & {
  location?: string
}

type ScreenViewType = CommonGameEventProps & {
  screen_name: EventScreenNameValues
  previous_screen?: EventScreenNameValues
}

type ObjectClickType = CommonGameEventProps & {
  screen_name: EventScreenNameValues
  text: string /**  text = the text/name of the CTA */
  type: EventClickTypeValues /**  type = the type of CTA {button, interstitial, banner} */
  destination?: EventScreenNameValues /**  destination = the screen name that the CTA directs the user to */
  click_name: EventClickNamesValues /**  click_name = the name of the click */
  value?: string /**  value = the value of the click */
}

type OBIframeLaunchType = {
  context: HandShakeContextType
}

// many events have different payloads, so we need to define a type that can handle all of them
// we do not need to create lots of hook functions for each event, we can use this hook to track all events
export type SpecificEventType = {
  screen_view: ScreenViewType
  object_click: ObjectClickType
  error_display: ErrorDisplayType
  error_occur: ErrorOccurEventType
  fit_test_start: FitTestStatusType
  fit_test_finish: FitTestStatusType
  game_start: CommonGameEventProps
  game_finish: CommonGameEventProps
  game_abort: GameAbortType
  game_output: GameOutputType
  game_crash: GameCrashEventType
  workout_start: WorkoutStartEventType
  workout_finish: WorkoutFinishEventType
  workout_game_swap: WorkoutSwapEventType
  sign_out: DefaultPayloadType
  email_training_reminders_select: EmailTrainingReminderType
  unsupported_browser_error: UnsupportedBrowserErrorType
  ob_iframe_launch: OBIframeLaunchType
  client_sign_in: DefaultPayloadType
  ld_segmentation: DefaultPayloadType
}

const useTrackEvents = () => {
  const {
    currentScreen,
    clients: { lumosEventApi },
  } = useContext(EventsContext)

  /**
   *
   * Event Name are the event names
   * Event Payload are the data that needs to be sent with the event
   *
   * Usage
   * trackEvent(eventName, payload)
   *
   * Example:
   * trackEvent('fit_test_start', { game_slug: 'fit_test_game_1',  })
   *
   * Intention of trackEvent is to become a single point of tracking events based on the event name
   * This gives strong type safety at single place
   * Other tracking hooks can use this hook to track events
   */
  const trackEvent = useCallback(
    <T extends EventNameValue>(eventName: T, payload: SpecificEventType[T]) => {
      lumosEventApi.track(eventName, payload)
    },
    [lumosEventApi],
  )

  const trackErrorDisplay = useCallback(
    ({ error_message }: Omit<SpecificEventType['error_display'], 'screen_name'>) => {
      trackEvent('error_display', {
        screen_name: currentScreen,
        error_message: error_message,
      })
    },
    [currentScreen, trackEvent],
  )

  const trackErrorEvent = useCallback(
    ({ click_name, game_slug, ...rest }: Omit<SpecificEventType['error_occur'], 'screen_name'>) => {
      try {
        trackEvent('error_occur', {
          ...rest,
          ...(click_name && { click_name }),
          ...(game_slug && { game_slug }),
          screen_name: currentScreen as EventScreenNameValues,
        })
      } catch (error) {
        logger.error('useTrackEvents::trackErrorEvent::catch', error)
      }
    },
    [currentScreen, trackEvent],
  )

  return {
    trackEvent,
    trackErrorEvent,
    trackErrorDisplay,
  }
}

export default useTrackEvents
