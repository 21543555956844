import { Session } from '@ory/kratos-client'
import { isAxiosError } from 'axios'
import cookie from 'cookie'
import { err, ok, Result } from 'neverthrow'
import { NextPageContext } from 'next'

import logger from '~/utils/logger'

import { getOryFrontendClient } from '../clients/oryClient'

export const getJWTPayload = (token: string | undefined): any | null => {
  // use this to get your token quickly!
  // logger.log(token)
  if (!token) return null
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_, userInfo] = token.split('.')
  const payload = Buffer.from(userInfo, 'base64')
  return JSON.parse(payload.toString())
}

export const parseUser = (token: string | undefined | null): LumosUser | undefined => {
  if (!token) return undefined
  const { exp, flow, lumos_user_id } = getJWTPayload(token)
  // Always make sure userId is a number, this has dependency on currentUserVar, which is passed to many apis.
  const userId = Number(lumos_user_id)

  return {
    id: userId,
    authExpiration: new Date(exp * 1000).valueOf(),
    flow,
  }
}

export async function attemptOryLogout(onError?: (error: unknown) => void) {
  const oryFrontendClient = getOryFrontendClient()
  try {
    const { data: flow } = await oryFrontendClient.createBrowserLogoutFlow()
    await oryFrontendClient.updateLogoutFlow({
      token: flow.logout_token,
    })
  } catch (error) {
    if (isAxiosError(error) && error.response?.data.error.id !== 'session_inactive') {
      logger.debug('Failed to remove ory session', error)
    }
    onError?.(error)
  }
}

export const readStream = async (stream: ReadableStream<Uint8Array> | null): Promise<Uint8Array[]> => {
  if (!stream) return [] as Uint8Array[]
  const reader = stream.getReader()
  const chunks = [] as Uint8Array[]

  let ended
  while (!ended) {
    const { done, value } = await reader.read()
    ended = done
    if (done) {
      return chunks
    }
    chunks.push(value as Uint8Array)
  }
  return [] as Uint8Array[]
}

export function parseCookies(req: NextPageContext['req']) {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}

class EncodingError extends Error {}

function base64StringFromJSON(jsonObj: object): Result<string, EncodingError> {
  try {
    return ok(Buffer.from(JSON.stringify(jsonObj)).toString('base64'))
  } catch (error) {
    return err(
      new EncodingError(error instanceof Error ? error.message : `Failed to encode ${jsonObj} as a base64 string`),
    )
  }
}

export class OrySessionParseError extends Error {}

export function generateJwtFromOrySession(session: Session): Result<string, OrySessionParseError | EncodingError> {
  const { lumosUserId } = session.identity?.metadata_public as { lumosUserId?: string }
  if (!lumosUserId) {
    return err(new Error('Failed to associate the logged in user with a Lumos User ID'))
  }
  const expiresAtDateString = session.expires_at
  if (!expiresAtDateString) {
    return err(new Error('The logged in user does not have a valid expiration for their session'))
  }
  const userInfo = {
    lumos_user_id: lumosUserId,
    exp: new Date(expiresAtDateString).valueOf() / 1000,
  }
  const jwtPayloadResult = base64StringFromJSON(userInfo)
  if (jwtPayloadResult.isErr()) {
    return err(jwtPayloadResult.error)
  }
  const jwt = `_.${jwtPayloadResult.value}._`

  return ok(jwt)
}

export function getBrowserCookieOpts() {
  // including the leading dot wouldn't be harmful for localhost, but not including it as a small
  // service for developers since cookies pasted directly into devtools will default to `localhost`,
  // so avoid accidental dupes.
  const domain = location.hostname === 'localhost' ? location.hostname : `.${location.hostname}`
  return {
    domain,
    path: '/',
    sameSite: true,
  }
}
