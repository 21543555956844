import { DayOfWeek, PlanProvider } from '~/graphql/generated/schema'

export const RANK_COLORS: Record<number, LumosColor> = {
  0: 'coolGray82',
  1: 'kaleBase',
  2: 'tealBase',
  3: 'orangeBase',
  4: 'tomatoBase',
  5: 'plumBase',
}

export const RANKS_KEYED_BY_NUMBER: Record<number | string, string> = {
  0: 'noBadges',
  1: 'newcomer',
  2: 'explorer',
  3: 'strategist',
  4: 'brainiac',
  5: 'mastermind',
} as const

export const RANKS_KEYED_BY_NAME: Record<string, number> = {
  newcomer: 1,
  explorer: 2,
  strategist: 3,
  brainiac: 4,
  mastermind: 5,
}

export const REQUIRED_PLAYS: Record<number | string, number> = {
  1: 3,
  2: 5,
  3: 10,
  4: 15,
  5: 20,
} as const

export const UserHelpCenterUrl = `/api/helpCenter?source=L2`
export const DeleteAccountHelpCenterUrl = `/api/helpCenter?source=L2&action=delete_account`

export const FooterLinks = {
  termsOfService: { code: 'termsOfService', url: '/terms-of-service' },
  privacyPolicy: { code: 'privacyPolicy', url: '/privacy-policy' },
  californiaPrivacy: { code: 'californiaPrivacy', url: '/privacy-policy#what-information-we-collect' },
  help: { code: 'help', url: UserHelpCenterUrl },
} as const

export const SUPPORT_EMAIL = 'accounts@lumosity.com'

/** Using this url as postSignIn/postSignUp returnTo url in ORY */
/** if you are changing this, make sure you update the allowed list in ORY CONSOLE */
export const AuthCallbackUrl = '/auth-callback'
export const PUBLIC_PATHS = [
  AuthCallbackUrl,
  '/signup',
  '/login',
  '/loginMobile',
  '/account-recovery',
  '/landing',
  '/fb-auth',
  '/privacy-policy',
  '/terms-of-service',
  '/payment-policy',
  '/family-invitation/',
  '/flags',
]
// Don't redirect to the onboard survey from these paths
export const PRE_FIT_TEST_PATHS = [
  '/onboard',
  '/fit-test',
  '/settings',
  '/subscribe',
  '/payment-confirmation',
  '/checkout',
  ...PUBLIC_PATHS,
]

export const UNKNOWN_STAT_KEY = 'unknown'

export const LUMOSITY_USER_COOKIE = 'lumosityUser'
export const FB_MOBILE_AUTH_FLOW_COOKIE = 'fbMobileAuthFlow'
export const DUMMY_USER_ID = '96b0cff4-d219-4d25-b38c-199ab279a591'

export enum GameListFilter {
  byArea,
  byBadge,
}

export const FitTestGame = {
  'color-match': 'color-match',
  'train-of-thought': 'train-of-thought',
  'memory-matrix': 'memory-matrix',
} as const

export enum PlayContext {
  Workout = 'workout',
  FreePlay = 'games',
  FitTest = 'fit_test',
}

export const PLATFORM_WEB = 'web'

export const LIMITED_WORKOUT_GAME_TOTAL = 3
export const PREMIUM_WORKOUT_GAME_TOTAL = 5

// See https://github.com/lumoslabs/lumosity-frontend#number-of-games
export const BASE_COUNT_EN_WEB_GAMES = 40
export const BASE_COUNT_INTL_WEB_GAMES = 40

export enum HeaderTypes {
  lumos = 'lumos',
  freeplayPreGame = 'freeplayPreGame',
  freeplayPostGame = 'freeplayPostGame',
  postWorkoutGame = 'postWorkoutGame',
  survey = 'survey',
  login = 'login',
  preWorkoutGame = 'preWorkoutGame',
  preFitTestGame = 'preFitTestGame',
  fitTest = 'fitTest',
  stepGame = 'stepGame',
  empty = 'empty',
  subscribe = 'subscribe',
  noTopNav = 'noTopNav',
  legal = 'legal',
}

export const skipQuestionResponse = 'unspecified'

export const supportedCurrencies = ['USD', 'CAD', 'AUD', 'GBP', 'EUR', 'JPY', 'MXN']

export const PaymentProviderLabels: Record<PlanProvider, { key: string } | { label: string }> = {
  apple: { label: 'Apple' },
  google: { label: 'Google' },
  stripe: { key: 'creditCard' },
  flagship_litle: { key: 'creditCard' },
  flagship_adyen: { key: 'creditCard' },
  braintree: { label: 'PayPal' },
  flagship_paypal: { label: 'PayPal' },
  flagship_paypal_remote: { label: 'PayPal' },
  lumos: { label: 'Lumosity' },
  flagship_activation_code: { label: 'Lumosity' },
  flagship: { label: 'Lumosity' },
  flagship_unknown: { label: 'Lumosity' },
} as const

export const flagshipSubscriptionManagementLink = 'https://v1.lumosity.com/shop/settings/edit'

export const appleSubscriptionManagementLink = 'https://apps.apple.com/account/subscriptions'

export const appleSubscriptionManagementFaqLink = 'https://support.apple.com/kb/HT4098'

export const googleSubscriptionManagementLink =
  'https://play.google.com/store/account/subscriptions?package=com.lumoslabs.lumosity'

export const googleSubscriptionManagementFaqLink = 'https://support.google.com/googleplay/answer/2476088'

// If introducing a new language, make sure to validate all dependency id this variables, especially GamesLibrary.tsx
export const AllSupportedLanguages: SupportedLangType[] = ['en', 'de', 'es', 'fr', 'ja', 'ko', 'pt']

// These are optional-chained in case a test mocks out schema.ts
export const AllDaysOfWeek: DayOfWeek[] = [
  DayOfWeek?.Sunday,
  DayOfWeek?.Monday,
  DayOfWeek?.Tuesday,
  DayOfWeek?.Wednesday,
  DayOfWeek?.Thursday,
  DayOfWeek?.Friday,
  DayOfWeek?.Saturday,
]

export const gameAssetBasePath = 'https://asset.lumosity.com/game-assets/'

export const ModalStyles = {
  zIndex: 10000,
}
export const UTMSessionStorageName = 'utm_parameters'

export const AppDimensions = {
  sideBarCloseWidth: '80px',
  sideBarOpenWidth: '260px',
  navBarHeight: '88px',
}

export const SystemPromotionCodes = {
  Day0: 'SYSTEM_DAY0',
}

export const ActiveWorkoutCookie = 'l2_active_workout'

type AreaSlugNamesType = { [Key in AreaSlugType]: Key }
// AreaSlugNamesType ensures all keys of area slug is present, so that we don't miss any &
// it make sure that key and value are same, so we don't map different key with different value
const AreaSlugNames: AreaSlugNamesType = {
  attention: 'attention',
  flexibility: 'flexibility',
  memory: 'memory',
  'problem-solving': 'problem-solving',
  math: 'math',
  speed: 'speed',
  language: 'language',
} as const

export const AreaSlugList: AreaSlugType[] = Object.values(AreaSlugNames)
// At few places in application language areas are skipped
export const NonLanguageAreaList = Object.values(AreaSlugNames).filter((key) => key !== 'language')
