import { useState, useEffect, useRef } from 'react'

import { useReactiveVar } from '@apollo/client'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'

import PageLoader from '~/components/ui/PageLoader'
import { currentUserVar } from '~/graphql/reactive-vars'
import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()
const isLDOffline = publicRuntimeConfig?.features?.offline
const ldClientSideID = publicRuntimeConfig?.features?.clientSideID
const isLDAvailable = !isLDOffline && ldClientSideID
type LDProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => JSX.Element

export const FeaturesProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const currentUser = useReactiveVar(currentUserVar)
  const userId = currentUser?.id
  const providerRef = useRef<LDProvider | null>(null)
  const [isReady, setIsReady] = useState(false) // we want component to rerender when its ready

  useEffect(() => {
    if (!isLDAvailable) {
      logger.debug('[LaunchDarkly] - Offline mode')
      setIsReady(true)
      return
    }

    if (!userId) {
      // https://launchdarkly.com/docs/home/observability/anonymous-contexts#wait-to-initialize-your-sdk
      // if user is not there, we don't need to initialize LD
      // as of now we don't have anonymous user feature flag to be evaluated.
      // helps in reducing MAU count
      setIsReady(true)
      return
    }

    ;(async () => {
      const component = await asyncWithLDProvider({
        clientSideID: ldClientSideID,
        context: {
          kind: 'user',
          key: String(userId),
        },
        options: {
          streaming: true, // TODO - check if we need to manually enable streaming, or is default is already true with new sdk version
          logger: {
            debug: (...args) => logger.debug('[LaunchDarkly]', ...args),
            info: (...args) => logger.info('[LaunchDarkly]', ...args),
            warn: (...args) => logger.warn('[LaunchDarkly]', ...args),
            error: (...args) => logger.error('[LaunchDarkly]', ...args),
          },
        },
        reactOptions: {
          useCamelCaseFlagKeys: false,
        },
      })
      providerRef.current = component
    })()
      .catch((e) => {
        logger.error('FeaturesProvider::Error', e)
        providerRef.current = null // resetting to null, as error is occurred
      })
      .finally(() => {
        setIsReady(true)
      })
  }, [userId])

  if (!isReady) {
    return <PageLoader />
  }

  const Provider = providerRef.current

  if (isLDAvailable && Provider) {
    return <Provider>{children}</Provider>
  } else {
    return <>{children}</>
  }
}
